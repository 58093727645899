import { parse, toSeconds } from 'iso8601-duration';
import { EpgProgram, WebImage } from './contracts';
import { LiveEpgProgram } from './LiveEpgProgram';

let dateParserRegex: RegExp;
function parseDate(textDate: string) {
  dateParserRegex = dateParserRegex || /^\/Date\((\d+)(?:[-+](\d+))?\)\/$/;

  const result = dateParserRegex.exec(textDate);
  if (!result || !result[1]) {
    return null;
  }

  return parseInt(result[1], 10);
}

export default function parseEpgLiveBuffer(data: EpgProgram[] = []): LiveEpgProgram[] {
  return data.map((program) => {
    const { title, description, programId, image } = program;

    const images = (image && image.webImages) || [];
    const poster = images[images.length - 1];
    const { imageUrl: posterImage } = poster || ({} as WebImage);

    const duration = program.duration ? toSeconds(parse(program.duration)) : 0,
      plannedStart = parseDate(program.plannedStart) || 0,
      actualStart = parseDate(program.actualStart) || 0,
      actualEnd = actualStart && duration ? actualStart + duration * 1000 : 0;

    return {
      title,
      description,
      programId,
      duration,
      plannedStart,
      actualStart,
      actualEnd,
      images,
      posterImage,
    };
  });
}
