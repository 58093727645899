import { getLogger } from '../logging/logger';

export function setLogLevel(level: cast.framework.LoggerLevel) {
  const context = cast.framework.CastReceiverContext.getInstance();
  context.setLoggerLevel(level);

  if (cast.player) {
    setPlayerLogLevel(level);
  } else {
    context.addEventListener(cast.framework.system.EventType.READY, () => {
      setPlayerLogLevel(level);
    });
  }
}

export function setPlayerLogLevel(level: cast.framework.LoggerLevel) {
  try {
    cast.player.api.setLoggerLevel(level as cast.player.api.LoggerLevel);
  } catch (_e) {
    getLogger('setPlayerLogLevel').log('Could not set log level of cast.player');
  }
}
