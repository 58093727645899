import { MediaInformationCustomData } from './custom-data/media-information-custom-data';
import PlaybackProgression from './PlaybackProgression';
import { TimeManager } from './time-manager';

export function applyProgressionToMediaInformation(
  mediaInformation: cast.framework.messages.MediaInformation | undefined,
  playbackProgression: PlaybackProgression,
  timeManager: TimeManager
) {
  if (!timeManager.isLive() || !mediaInformation) {
    return false;
  }

  // Do we actually need this? Documentation says it's -1 for LIVE streams.
  // mediaInformation.duration = undefined;

  const customData: MediaInformationCustomData = mediaInformation.customData ?? {};
  customData.liveProgress = playbackProgression.progress;
  customData.live = {
    progress: playbackProgression.progress,
    currentTimeSec: playbackProgression.relativeCurrentTime,
    durationSec: playbackProgression.duration,
    currentTimeTimestamp: playbackProgression.currentLiveTime.getTime(),
    startOfBufferTimestamp: playbackProgression.startOfBufferTime.getTime(),
    endOfBufferTimestamp: playbackProgression.endOfBufferTime.getTime(),
    streamStartedTimestamp: playbackProgression.startOfBufferInitialTime,
    timeSinceStaredSec: playbackProgression.timeSinceLiveStarted,
    timeSinceStartedSec: playbackProgression.timeSinceLiveStarted,
    valuesUpdatedTimestamp: new Date().getTime(),

    // To be removed. Waiting for radio to upgrade chromecast-sender to 3.1.6+.
    // https://github.com/nrkno/cast.nrk.no/pull/1275
    currentTime: timeManager.getCurrentTimeSec(),
    seekableRange: timeManager.getLiveSeekableRange() as Required<ReturnType<TimeManager['getLiveSeekableRange']>>,
    programDateTime: timeManager.getStartAbsoluteTime(),
    castTimeOffset: timeManager.getCurrentTimeOffset(),
  };

  mediaInformation.customData = customData;

  return true;
}
