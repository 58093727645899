import EventEmitter from 'eventemitter3';
import { Events as AnalyticsEvents, EventArgs as AnalyticsEventArgs } from '@nrk/media-analytics';

function keysOf<T>(o: { [key: string]: unknown }) {
  return Object.keys(o) as Array<keyof T>;
}

enum DelayingEventsState {
  Delayed,
  Released,
}

export class EventForwarder {
  private emitter: EventEmitter<AnalyticsEventArgs>;
  private ludoToAnalyticsEventMapping: Record<string, (...args: unknown[]) => unknown>;
  private isAttached: boolean;
  private video: HTMLVideoElement;
  private delayingEvents?: DelayingEventsState;
  private delayingEventsPromise: Promise<void>;
  private releaseDelayedEvents: () => void;

  constructor(video: HTMLVideoElement, emitter: EventEmitter<AnalyticsEventArgs>) {
    this.video = video;
    this.emitter = emitter;
    this.isAttached = false;

    this.forwardTimeUpdate = this.forwardTimeUpdate.bind(this);
    this.setupDelayedEvents();

    const forwardEvent = (
      analyticsEvent: Exclude<
        AnalyticsEvents,
        AnalyticsEvents.BITRATESWITCH | AnalyticsEvents.PROGRAM_CHANGED | AnalyticsEvents.TIMEUPDATE
      >
    ) => {
      return () => {
        this.delayingEventsPromise.then(() => this.emitter.emit(analyticsEvent));
      };
    };

    this.ludoToAnalyticsEventMapping = {
      emptied: forwardEvent(AnalyticsEvents.UNLOAD),
      ended: forwardEvent(AnalyticsEvents.ENDED),
      error: forwardEvent(AnalyticsEvents.ERROR),
      loadeddata: forwardEvent(AnalyticsEvents.LOADED),
      loadstart: forwardEvent(AnalyticsEvents.INTENT_TO_PLAY),
      pause: forwardEvent(AnalyticsEvents.PAUSE),
      playing: forwardEvent(AnalyticsEvents.PLAYING),
      seeked: forwardEvent(AnalyticsEvents.SEEKED),
      seeking: forwardEvent(AnalyticsEvents.SEEKING),
    };
  }

  setupDelayedEvents() {
    this.delayingEvents = DelayingEventsState.Delayed;
    this.delayingEventsPromise = new Promise<void>((resolve) => {
      this.releaseDelayedEvents = resolve;
    });
  }

  start() {
    this.releaseDelayedEvents?.();
  }

  attachForwardEvents() {
    if (this.isAttached) {
      return;
    }
    keysOf(this.ludoToAnalyticsEventMapping).forEach((event) => {
      const forwardFunc = this.ludoToAnalyticsEventMapping[event];
      this.video.addEventListener(event, forwardFunc);
    });
    this.video.addEventListener('timeupdate', this.forwardTimeUpdate);
    this.isAttached = true;
  }

  detachForwardEvents() {
    if (!this.isAttached) {
      return;
    }
    keysOf(this.ludoToAnalyticsEventMapping).forEach((event) => {
      const forwardFunc = this.ludoToAnalyticsEventMapping[event];
      this.video.removeEventListener(event, forwardFunc);
    });
    this.video.removeEventListener('timeupdate', this.forwardTimeUpdate);
    this.isAttached = false;
  }

  private forwardTimeUpdate(): void {
    this.emitter.emit(AnalyticsEvents.TIMEUPDATE, this.video.currentTime);
  }
}
