import { getLogger } from '../logging/logger';

export interface HlsMediaPlaylist {
  start: number;
  end: number;
  endList: boolean;
  programTime?: number;
  mediaSequence: number;
  segmentDurations: number[];
  targetDuration: number;
  playlistType?: string;
  updatedTime: number;
  version?: number;
}

const logger = getLogger('HlsMediaPlaylistParser');

/**
 * Parse the media playlist of an HLS stream. For live streams, updated media
 * playlists can be parsed too, getting correct time values for sliding
 * windows.
 *
 * Feed the parseManifest() method with the data from
 * playbackConfig.manifestHandler.
 *
 * It resets itself for new streams by assuming that manifests that are not HLS
 * media playlists indicate change in media.
 */
export class HlsMediaPlaylistParser {
  hlsMediaPlaylist?: HlsMediaPlaylist;

  /**
   * Process the just fetched HLS manifest (master or media playlist) and
   * extract only media playlist data.
   */
  parseManifest(manifest: string): HlsMediaPlaylist | undefined {
    const perfStart = Date.now();
    const keyValueReader = readKeyValues(manifest);

    // Is the data actually HLS?
    const firstLine = keyValueReader.next();
    if (!firstLine.done && firstLine.value[0] !== '#EXTM3U') {
      this.hlsMediaPlaylist = undefined; // Reset.
      const isXML = firstLine.value[0].startsWith('<?xml');
      throw new TypeError(isXML ? 'Manifest seems to be DASH.' : `Manifest is not HLS. ${manifest.substring(0, 100)}`);
    }

    const playlist: HlsMediaPlaylist = {
      start: 0,
      end: 0,
      endList: false,
      mediaSequence: 0,
      segmentDurations: [],
      targetDuration: 0,
      updatedTime: Date.now() / 1000,
    };

    let duration = 0;

    for (const [key, value] of keyValueReader) {
      switch (key) {
        case '#EXTINF':
          {
            const segmentDuration = Number.parseFloat(value);
            playlist.segmentDurations.push(segmentDuration);
            duration += segmentDuration;
          }
          break;
        case '#EXT-X-VERSION':
          playlist.version = Number.parseInt(value, 10);
          break;
        case '#EXT-X-MEDIA-SEQUENCE':
          playlist.mediaSequence = Number.parseInt(value, 10);
          break;
        case '#EXT-X-PROGRAM-DATE-TIME':
          playlist.programTime = new Date(value).getTime() / 1000;
          break;
        case '#EXT-X-TARGETDURATION':
          playlist.targetDuration = Number.parseInt(value);
          break;
        case '#EXT-X-PLAYLIST-TYPE':
          playlist.playlistType = value;
          break;
        case '#EXT-X-ENDLIST':
          playlist.endList = true;
          break;
        default:
      }
    }

    if (duration === 0) {
      logger.log(`No segments. Assuming master playlist:`, manifest.substring(0, 100));
      this.hlsMediaPlaylist = undefined; // Reset.
      return undefined;
    }

    // Use the media sequence numbers and the segment durations of the previous
    // media playlist to set correct start time of the updated media playlist,
    // keeping position 0 fixed.
    const previousPlaylist = this.hlsMediaPlaylist;
    if (previousPlaylist !== undefined) {
      const sequenceJump = playlist.mediaSequence - previousPlaylist.mediaSequence;
      playlist.start =
        previousPlaylist.start +
        previousPlaylist.segmentDurations.slice(0, sequenceJump).reduce((sum, duration) => sum + duration, 0);
    }
    playlist.end = playlist.start + duration;

    const perfEnd = Date.now();
    const copy = { ...playlist, segmentDurations: '[removed]' };
    logger.log(`HLS media playlist (parse time ms: ${perfEnd - perfStart}):`, copy);

    this.hlsMediaPlaylist = playlist;
    return playlist;
  }
}

/**
 * Parse a text into key/value pairs by newlines and the first colon on each
 * line. The newlines, return characters and colons are not included in the
 * output. If a line doesn't have a colon, then the whole line is in the key
 * and the value becomes an empty string.
 */
function* readKeyValues(text: string) {
  let pair: [key: string, value: string] = ['', ''];
  let index = 0;
  let pairYielded = false;

  for (const c of text) {
    switch (c) {
      case '\n':
        yield pair;
        pairYielded = true;
        pair = ['', ''];
        index = 0;
        break;
      case '\r':
        break;
      case ':':
        if (index === 0) {
          index = 1;
          break;
        }
      // eslint-disable-next-line no-fallthrough
      default:
        pair[index] += c;
        pairYielded = false;
    }
  }

  if (!pairYielded) {
    yield pair;
  }
}
