import { config } from '../appConfig';
import { httpGetJson } from '../http/httpGetJson';
import { ICatalogSeries } from './contracts/CatalogSeries';
import { ICatalogSeason } from './contracts/CatalogSeason';

export function getSeries(seriesId: string): Promise<ICatalogSeries> {
  return httpGetJson(`${config.apiBase}/tv/catalog/series/${seriesId}`);
}

export function getSeason(seasonUrl: string): Promise<ICatalogSeason> {
  return httpGetJson(`${config.apiBase}${seasonUrl}`);
}
