const DEFAULT_SCHEME = 'nrk';

/*
 * Supported formats, matching segments (words/numbers) separated by ':'. The
 * patterns below match 1 to 4 segments, with keys and potentially required
 * values, in order.
 */
const URI_SEGMENT_MAP: [string, string | undefined][][] = [
  [
    // Example: p1
    ['id', undefined],
  ],
  [
    // Example: nrk:p1
    ['scheme', undefined],
    ['id', undefined],
  ],
  [
    // Example: nrk:podcast:l_20358447-91cc-440b-b584-4791cc940bde
    ['scheme', 'nrk'],
    ['type', 'podcast'],
    ['episode', undefined],
  ],
  [
    // Example: nrk:channel:p1
    ['scheme', undefined],
    ['type', undefined],
    ['id', undefined],
  ],
  [
    // Example: nrk:podcast:bjoernen_lyver:l_f106ffee-bbbb-407e-86ff-eebbbb907e57
    ['scheme', 'nrk'],
    ['type', 'podcast'],
    ['series', undefined],
    ['episode', undefined],
  ],
  [
    ['scheme', 'nrk'],
    ['type', 'radioclip'],
    ['clipType', undefined],
    ['id', undefined],
  ],
];

/**
 * Identifies a piece of media, which can be then be located and loaded by
 * services which recognize the different parts of the URI.
 */
export class MediaURI {
  scheme: string;
  type?: string;
  series?: string;
  clipType?: string;
  episode?: string;
  id?: string;

  private constructor(parts: Record<string, string>) {
    this.scheme = parts.scheme ?? DEFAULT_SCHEME;
    this.type = parts.type;
    this.series = parts.series;
    this.clipType = parts.clipType;
    this.episode = parts.episode;
    this.id = parts.id;
  }

  static parse(uri: string): MediaURI | undefined {
    if (!MediaURI.isValidFormat(uri)) {
      return undefined;
    }

    const segments = uri.split(':');

    const parsedSegments = URI_SEGMENT_MAP.filter((targetSegments) => targetSegments.length === segments.length)
      .map((targetSegments) =>
        targetSegments.reduce(
          (result, targetSegment, index) => {
            // Undefined means a previous segment mismatched.
            if (result === undefined) {
              return result;
            }

            // If the target segment has a required value, then it must match.
            if (targetSegment[1] !== undefined && targetSegment[1] !== segments[index]) {
              return undefined;
            }

            result[targetSegment[0]] = segments[index];
            return result;
          },
          {} as Record<string, string> | undefined
        )
      )
      .find((result) => result !== undefined);

    return parsedSegments ? new MediaURI(parsedSegments) : undefined;
  }

  static isValidFormat(uri: string): boolean {
    return /^[\wæøå-]+(:[\wæøå-]+){0,3}$/i.exec(uri) !== null;
  }

  /**
   * Returns the URL segments as a URL path, ignoring the scheme.
   *
   * For example: nrk:channel:p1 becomes /channel/p1.
   */
  asPath(): string {
    return `/${[this.type, this.series, this.episode, this.clipType, this.id].filter((s) => s).join('/')}`;
  }

  toString(): string {
    return [this.scheme, this.type, this.series, this.episode, this.clipType, this.id].filter((s) => s).join(':');
  }
}
