import window from 'global/window';
import EventEmitter from 'eventemitter3';
import { getLogger } from '../logging/logger';

declare global {
  interface Window {
    ga: {
      (...args: unknown[]): void;
      q?: unknown[];
      l?: number;
    };
    gaCalls: unknown[];
    gaListener: Array<(...args: unknown[]) => void>;
  }
}

const logger = getLogger('GoogleAnalyticsListener');
let originalGaFunc: (...args: unknown[]) => void;

function gaOverride(...args: unknown[]) {
  window.gaCalls.push(args);
  window.gaListener.forEach((listener) => {
    listener(...args);
  });
  if (typeof originalGaFunc === 'function') {
    originalGaFunc(...args);
  }
}

// Create a GA listener
export default class GoogleAnalyticsListener {
  emitter: EventEmitter;

  constructor() {
    this.emitter = new EventEmitter();

    window.gaCalls = window.gaCalls || [];
    window.gaListener = window.gaListener || [];
    window.gaListener.push((...args) => this.emitter.emit('ga', ...args));

    if (window.ga !== gaOverride) {
      logger.log('override window.ga');

      if (typeof window.ga === 'undefined') {
        window.ga = function () {
          window.ga.q = window.ga.q || [];
          // eslint-disable-next-line prefer-rest-params
          window.ga.q.push(arguments);
        };
        window.ga.l = new Date().getTime();
      }

      window.ga(() => {
        // hijacking window.ga
        originalGaFunc = window.ga;
        window.ga = gaOverride;
      });
    }
  }

  on(event: string | symbol, fn: EventEmitter.ListenerFn, context?: unknown) {
    this.emitter.on(event, fn, context);
  }

  off(event: string | symbol, fn?: EventEmitter.ListenerFn) {
    this.emitter.off(event, fn);
  }
}
