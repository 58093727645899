// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class SimpleListener<T extends (...args: any[]) => any> {
  private readonly _listeners: T[] = [];

  listen(listener: T) {
    this._listeners.push(listener);
  }

  emit(...args: Parameters<T>) {
    this._listeners.forEach((listener) => listener(...args));
  }

  destroy() {
    this._listeners.splice(0, this._listeners.length);
  }
}
