import type { MediaItem } from '../../../../types/nrk/mediaitem';
import { LoadedResult, Loader, Medium, PlayerContext } from './Loader';
import SessionState from '../SessionState';
import { getAuthHeaders, getMediaItem, getPreferredManifestByContentId } from '../../psapi/getMediaItem';
import { AuthenticationHandler } from '../AuthenticationHandler';
import { LoadRequestDataCustomData } from '../custom-data/load-request-custom-data';
import { MediaInformationCustomData } from '../custom-data/media-information-custom-data';

export default class MediaElementLoader implements Loader {
  private _sessionState: SessionState;
  private _authenticationHandler: AuthenticationHandler;

  constructor(sessionState: SessionState, authenticationHandler: AuthenticationHandler) {
    this._sessionState = sessionState;
    this._authenticationHandler = authenticationHandler;
  }

  async load(loadRequestData: cast.framework.messages.LoadRequestData): Promise<LoadedResult> {
    const contentId = loadRequestData.media.contentId;

    const customData: LoadRequestDataCustomData | undefined = loadRequestData.customData;
    const preferredManifestFromContentId = getPreferredManifestByContentId(contentId);
    const preferredManifestFromLoadRequest = customData && customData.preferredManifest;
    const preferredManifestFromSession = this._sessionState.preferredManifest;
    const preferredManifestNames = [
      preferredManifestFromContentId,
      preferredManifestFromLoadRequest,
      preferredManifestFromSession,
    ];

    const mediaItem = await getMediaItem(
      contentId,
      preferredManifestNames,
      !!customData?.subtitle, // enableLiveSubtitles
      this.getHeaders(),
      this._authenticationHandler
    );

    if (mediaItem.nonPlayable) {
      throw new NonPlayableError(
        `NonPlayable reason ${mediaItem.nonPlayable.reason}:${mediaItem.nonPlayable.message}:${this._authenticationHandler.userId}`,
        mediaItem
      );
    }

    const mediaInformation = loadRequestData.media;
    if (mediaItem) {
      applyMediaElementToMediaInformation(mediaItem, mediaInformation);
    }

    const context = mapToPlayerContext(mediaItem);
    const progressLink = mediaItem.progressLink;

    const mediaCustomData: MediaInformationCustomData = mediaInformation.customData ?? {};
    // Potentially set for external load requests. Undefined for queue based
    // requests => The TextTrackHandler will manage which track is active
    // between episodes.
    mediaCustomData.activeTextTrack = customData?.subtitle;

    return {
      loadRequestData,
      context,
      mediaItem,
      progressLink,
    };
  }

  private getHeaders() {
    return getAuthHeaders(this._authenticationHandler);
  }
}

function applyMediaElementToMediaInformation(
  mediaItem: MediaItem,
  mediaInformation: cast.framework.messages.MediaInformation
) {
  const mediaAsset = mediaItem.mediaAssets[0];
  mediaInformation.contentId = mediaItem.loadRequestContentId ?? mediaItem.id;
  if (mediaAsset) {
    mediaInformation.contentUrl = mediaAsset.url;
    mediaInformation.contentType = mediaAsset.mimeType;
  }
  mediaInformation.duration = mediaItem.duration || undefined;
  mediaInformation.streamType = mediaItem.isLive
    ? cast.framework.messages.StreamType.LIVE
    : cast.framework.messages.StreamType.BUFFERED;

  const metadata = new cast.framework.messages.GenericMediaMetadata();
  metadata.title = mediaItem.title;
  metadata.subtitle = mediaItem.subtitle;

  metadata.images = mediaItem.images.map((image) => {
    const castImage = new cast.framework.messages.Image(image.imageUrl);
    castImage.width = image.pixelWidth;
    return castImage;
  });

  let altTextTracks: cast.framework.messages.Track[] | undefined;
  if (mediaItem.subtitles) {
    altTextTracks = mediaItem.subtitles.map((sub, index) => {
      const track = new cast.framework.messages.Track(index, cast.framework.messages.TrackType.TEXT);
      track.trackContentId = sub.webVtt;
      track.trackContentType = 'text/vtt';
      track.name = sub.label;
      track.language = sub.language;

      // Unsupported by PSAPI: SDH/role='caption', role='forced_subtitle'

      track.subtype = cast.framework.messages.TextTrackType.SUBTITLES;
      track.roles = ['subtitle'];

      if (sub.defaultOn) {
        track.roles.unshift('main');
      }

      return track;
    });
  }

  const customData: MediaInformationCustomData = mediaInformation.customData ?? {};
  customData.manifestName = mediaItem.manifestName;
  customData.currentManifest = mediaItem.currentManifest;
  customData.hasAllSpeechSubtitles = mediaItem.hasAllSpeechSubtitles;
  customData.isChannel = mediaItem.isChannel;
  customData.channelId = mediaItem.isChannel ? mediaItem.id : undefined;
  customData.altTextTracks = altTextTracks;

  if (mediaItem.nextEpisodeId) {
    const nextMedia = new cast.framework.messages.MediaInformation();
    nextMedia.contentId = mediaItem.nextEpisodeId;
    nextMedia.customData = { ...customData };
    customData.nextMedia = [nextMedia];
  }

  mediaInformation.customData = customData;
  mediaInformation.metadata = metadata;
}

export function mapToPlayerContext(mediaItem: MediaItem): PlayerContext {
  return {
    medium: mediaItem.mediaType === 'Audio' ? Medium.RADIO : Medium.TV,
    posterImage: mediaItem.images[mediaItem.images.length - 1].imageUrl,
  };
}

export class NonPlayableError extends Error {
  readonly metadata: MediaItem;
  constructor(message: string, metadata: MediaItem) {
    super(message);
    this.metadata = metadata;
  }
}
