import { getLogger } from '../logging/logger';
import CustomMessageHandler, { CustomCommands } from './CustomMessageHandler';
import { AuthenticationHandler } from './AuthenticationHandler';
import { AppInsightsTracker } from './AppInsightsTracker';
import { OneLiner } from '../ui/OneLiner';
import { LoadRequestDataCustomData } from './custom-data/load-request-custom-data';

const logger = getLogger('InviteCodeRedeemer');

export class InviteCodeRedeemer {
  private _authenticationHandler: AuthenticationHandler;
  private _appInsightsTracker: InterfaceOf<AppInsightsTracker>;
  private _customMessageHandler: CustomMessageHandler;
  private _oneLiner: OneLiner;
  private _previousUserId?: string; // Only used for suppressing repeated login messages.

  constructor(
    authenticationHandler: AuthenticationHandler,
    appInsightsTracker: InterfaceOf<AppInsightsTracker>,
    customMessageHandler: CustomMessageHandler,
    oneLiner: OneLiner
  ) {
    this._authenticationHandler = authenticationHandler;
    this._appInsightsTracker = appInsightsTracker;
    this._customMessageHandler = customMessageHandler;
    this._oneLiner = oneLiner;
  }

  /**
   * Redeem invite code once for each load request
   *
   * @param loadRequestData
   */
  async redeemFromLoadRequest(loadRequestData: cast.framework.messages.LoadRequestData) {
    const customData: LoadRequestDataCustomData | undefined = loadRequestData.customData;
    if (!customData || customData.inviteCodeHandled) {
      return;
    }
    customData.inviteCodeHandled = true;

    const inviteCode: string | undefined = customData ? customData.inviteCode : undefined;
    const codeChallenge: string | undefined = customData ? customData.codeChallenge : undefined;

    if (inviteCode && codeChallenge) {
      await Promise.race([
        this.redeemInviteCode(inviteCode, codeChallenge, loadRequestData.media.contentId),
        new Promise((resolve) => setTimeout(resolve, 5000)),
      ]);
    }
  }
  async redeemInviteCode(inviteCode: string, codeChallenge: string, contentId = 'empty-content-id') {
    try {
      const response = await this._authenticationHandler.redeemInviteCode(inviteCode, codeChallenge, contentId);
      const { userName, userId } = response;

      if (userId !== this._previousUserId) {
        this._previousUserId = userId;
        this._oneLiner.showMessage(`Hei, ${userName}`, { iconHref: '#nrk-user-loggedin' });
      }

      this._appInsightsTracker.setUserInfo({
        userId: this._authenticationHandler.userId,
        contentGroup: this._authenticationHandler.contentGroup,
        ageRestriction: this._authenticationHandler.ageRestriction,
      });
      this._appInsightsTracker.trackEvent('invite-code-success');
      this._customMessageHandler.sendCommand(CustomCommands.USER_AUTHENTICATED, {
        codeChallenge,
        userName,
        userId,
      });
    } catch (err) {
      logger.error('Error when logging in user', err);
      this._previousUserId = undefined;
      this._oneLiner.showMessage('Vi klarte ikke å logge deg inn');
      this._appInsightsTracker.setUserInfo({
        userId: this._authenticationHandler.userId,
        contentGroup: this._authenticationHandler.contentGroup,
        ageRestriction: this._authenticationHandler.ageRestriction,
      });
      this._appInsightsTracker.trackEvent('invite-code-failure', {
        invite: JSON.stringify({ inviteCode, codeChallenge }),
      });
      this._appInsightsTracker.trackException(err as Error);
      this._customMessageHandler.sendCommand(CustomCommands.USER_REJECTED, {
        codeChallenge,
      });
    }
  }
}
