import { getLogger } from '../logging/logger';
import NRKLiveEpg from '../NRKLiveEpg';
import EpgTrackerEvents, { EpgTrackerEventArgs } from '../NRKLiveEpg/EpgTrackerEvents';
import { MediaInformationCustomData } from './custom-data/media-information-custom-data';
import { TimeManager } from './time-manager';

const logger = getLogger('LiveEpgObserver');

export default class LiveEpgObserver {
  private playerManager: cast.framework.PlayerManager;
  private nrkLiveEpg: NRKLiveEpg;

  constructor(playerManager: cast.framework.PlayerManager, timeManager: TimeManager) {
    this.playerManager = playerManager;

    this.nrkLiveEpg = new NRKLiveEpg({
      currentLiveTime: () => new Date(timeManager.getAbsoluteTimeForMediaTime(timeManager.getCurrentTimeSec()) * 1000),
    });

    this.nrkLiveEpg.on(EpgTrackerEvents.LIVEPROGRAM_CHANGED, (prog) => {
      logger.log('EPG item', prog);
    });

    this.nrkLiveEpg.on(EpgTrackerEvents.EPG_UPDATED, (epg) => {
      logger.log('EPG update', epg);
    });

    this.playerManager.addEventListener(cast.framework.events.EventType.PLAYER_LOAD_COMPLETE, () => {
      const media = this.playerManager.getMediaInformation();
      const customData: MediaInformationCustomData = media.customData;

      if (customData.isChannel && customData.channelId !== undefined) {
        const channelId = customData.channelId;
        logger.log('EPG start', channelId);
        this.nrkLiveEpg.start(channelId);
      } else {
        logger.log('EPG stop');
        this.stop();
      }
    });

    this.playerManager.addEventListener(cast.framework.events.EventType.SEEKED, () => {
      this.nrkLiveEpg.resolve();
    });
  }

  on<E extends EpgTrackerEvents>(event: E, listener: (...args: EpgTrackerEventArgs[E]) => void) {
    this.nrkLiveEpg.on(event, listener);
  }

  off<E extends EpgTrackerEvents>(event: E, listener: (...args: EpgTrackerEventArgs[E]) => void) {
    this.nrkLiveEpg.off(event, listener);
  }

  stop() {
    this.nrkLiveEpg.stop();
  }
}
