import type { ICatalogSeries } from '../psapi/contracts/CatalogSeries';
import { getSeason } from '../psapi/getSeries';

export async function getFirstEpisodeInSeason(series: ICatalogSeries, seasonId: string): Promise<string | undefined> {
  const seasons = series._links.seasons || [];
  const season = seasons.find((s) => s.name === seasonId);
  if (!season) {
    return undefined;
  }

  const seasonResponse = await getSeason(season.href);

  const episodes = seasonResponse._embedded.episodes || seasonResponse._embedded.instalments || [];
  if (episodes.length === 0) {
    return undefined;
  }

  const firstEpisode = episodes[0];
  return firstEpisode ? firstEpisode.prfId : undefined;
}

export function getHighlightedSequentialEpisode(series: ICatalogSeries): string | undefined {
  const highlightedEpisode = series.sequential && series.sequential.highlightedEpisode;
  const seasons = series._embedded.seasons || [];

  let prfId: string | undefined;

  seasons.forEach((season) => {
    const episodes = (season._embedded && season._embedded.episodes) || [];
    const episode = episodes.find((e) => e.id === highlightedEpisode);
    if (episode !== undefined) {
      prfId = episode.prfId;
    }
  });

  return prfId;
}

export function getHighlightedStandardEpisode(series: ICatalogSeries): string | undefined {
  const highlightedInstalment = series.standard && series.standard.highlightedInstalment;
  if (highlightedInstalment === undefined) {
    return undefined;
  }

  const instalments = series._embedded.instalments;
  const instalmentsList = instalments !== undefined ? (Array.isArray(instalments) ? instalments : [instalments]) : [];

  const episode = instalmentsList.find((instalment) => instalment.id === highlightedInstalment);

  return episode !== undefined ? episode.prfId : undefined;
}
