import { getLogger } from '../logging/logger';
import { getMediaFromShareLink } from '../nrk/media-from-share-link';

const logger = getLogger('load-request-entity');

/**
 * The media.entity property of a load request is an "Optional Google Assistant
 * deep link to a media entity". If present, parse the entity value, identify
 * the target media and set the load request content ID accordingly. Then,
 * further processing of the load request can focus on the content ID.
 *
 * NRK uses NRK share links for Google Assistant deep linking.
 *
 * See:
 * https://developers.google.com/cast/docs/reference/web_receiver/cast.framework.messages.MediaInformation#entity
 * https://nrkconfluence.atlassian.net/wiki/spaces/spiller/pages/5038903/Delingslenker
 */
export async function resolveLoadRequestEntity(
  loadRequestData: cast.framework.messages.LoadRequestData
): Promise<void> {
  const { contentId, entity } = loadRequestData.media;

  if (!contentId && entity) {
    try {
      const resolvedEntity = await getMediaFromShareLink(entity);
      if (resolvedEntity !== undefined) {
        loadRequestData.media.contentId = resolvedEntity.mediaId;
        loadRequestData.currentTime = resolvedEntity.startTime;
      }
    } catch (e) {
      logger.error(e);
    }
  }
}
