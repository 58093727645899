import { LoadedResult, Loader, Medium } from './Loader';

const isMP3AudioUrlRegex = /\.mp3$/i;

export default class BasicLoader implements Loader {
  load(loadRequestData: cast.framework.messages.LoadRequestData): Promise<LoadedResult> {
    const { contentId } = loadRequestData.media;

    const isMP3Audio = isMP3AudioUrlRegex.test(contentId);
    const context = {
      medium: isMP3AudioUrlRegex.test(contentId) ? Medium.RADIO : Medium.TV,
    };

    /*
     * Using the contentType 'application/x-mpegurl' for mp3 will force the
     * player to download the full mp3, that will eventually crash the device.
     * By removing the contentType, the chromecast api will detect the
     * appropriate mime-type, and the mp3 will download partially (HTTP 206
     * range request). It's the NRK Radio app that sends the problematic mime
     * type.
     */
    if (isMP3Audio) {
      // @ts-expect-error: contentType must be string.
      loadRequestData.media.contentType = undefined;
    }

    return Promise.resolve({
      loadRequestData,
      context,
    });
  }
}
