import type { MediaItem } from '../../../types/nrk/mediaitem';
import type { AppConfig } from '../appConfig';
import * as debugging from '../debugging';
import { getLogger } from '../logging/logger';
import Slideshow from '../ui/Slideshow';
import HeadUpDisplay, { HudPlaybackState } from '../ui/HeadUpDisplay';
import BasicLoader from './loaders/BasicLoader';
import { LoadedResult, Loader, Medium, PlayerContext } from './loaders/Loader';
import MediaElementLoader, { mapToPlayerContext } from './loaders/MediaElementLoader';
import { setLogLevel } from './logging';
import Middlewares from './Middlewares';
import PlaybackProgression from './PlaybackProgression';
import TextTrackHandler from './TextTrackHandler';
import CustomMessageHandler, { CustomCommands, CustomNamespace } from './CustomMessageHandler';
import BackgroundLogo from '../ui/BackgroundLogo';
import SpinnerOverlay, { DummySpinnerOverlay, SpinnerOverlayInterface } from '../ui/SpinnerOverlay';
import CountdownHandler from './CountdownHandler';
import SessionState from './SessionState';
import safeLoadPosterImage from '../http/safeLoadPosterImage';
import ErrorObserver, { ErrorDetails } from './ErrorObserver';
import ErrorOverlay from '../ui/ErrorOverlay';
import { NrkLogo } from '../ui/NrkLogo';
import LiveEpgObserver from './LiveEpgObserver';
import PosterOverlay from '../ui/PosterOverlay';
import LiveRadioBehaviour from './LiveRadioBehaviour';
import ErrorBehaviour from './ErrorBehaviour';
import { debugPlayer } from './DebugPlayer';
import EpgTrackerEvents, { EpgProgramSet } from '../NRKLiveEpg/EpgTrackerEvents';
import { dateToTimeString, secondsToTimeString } from '../time';
import throttle from 'lodash-es/throttle';
import { resolveLoadRequestEntity } from './load-request-entity';
import NrkMediaLogger from '../tracking/NrkMediaLogger';
import { SeekRequestHandler } from './SeekRequestHandler';
import { AuthenticationHandler } from './AuthenticationHandler';
import { UserProgress, UserProgressObserver } from './UserProgressObserver';
import { UserProgressTracker } from './UserProgressTracker';
import { OneLiner } from '../ui/OneLiner';
import { AppInsightsTracker, NullAppInsightsTracker } from './AppInsightsTracker';
import { CodeChallengeProvider } from './CodeChallengeProvider';
import { MediaInformationApplier } from './MediaInformationApplier';
import { PlaybackStatsAggregator } from './stats/PlaybackStatsAggregator';
import { trackSessionStats } from './stats/trackSessionStats';
import { InviteCodeRedeemer } from './InviteCodeRedeemer';
import { NRKService } from '../nrkService';
import { MediaURI } from '../nrk/media-uri';
import { getAuthHeaders, getMediaItem } from '../psapi/getMediaItem';
import { MediaInformationCustomData } from './custom-data/media-information-custom-data';
import Gen1Message from '../ui/gen1-message';
import { getChromecastModel } from '../getChromecastModel';
import { TimeManager } from './time-manager';

const logger = getLogger('CafPlayer');

export default class CafPlayer {
  private playerManager: cast.framework.PlayerManager;
  private loader: Loader;
  private config: AppConfig;
  private delayLoadMiddleware = new Middlewares<void>();
  private backgroundLogo: BackgroundLogo;
  private slideshow: Slideshow;
  private hud: HeadUpDisplay;
  private playbackProgression: PlaybackProgression;
  private timeManager: TimeManager;
  private textTrackHandler: TextTrackHandler;
  private customMessageHandler: CustomMessageHandler;
  private castReceiverContext: cast.framework.CastReceiverContext;
  private spinner: SpinnerOverlayInterface;
  private spinnerState: boolean;
  private countdownHandler: CountdownHandler;
  private sessionState: SessionState;
  private videoElement: HTMLVideoElement;
  private tracker: NrkMediaLogger;
  private errorObserver: ErrorObserver;
  private errorOverlay: ErrorOverlay;
  private liveEpgObserver: LiveEpgObserver;
  private posterOverlay: PosterOverlay;
  private liveRadioBehaviour: LiveRadioBehaviour;
  private errorBehaviour: ErrorBehaviour;
  private playerDataBinder: cast.framework.ui.PlayerDataBinder;
  private updateMediaInformationThrottled: () => unknown;
  private seekRequestHandler: SeekRequestHandler;
  private authenticationHandler: AuthenticationHandler;
  private userProgressObserver: UserProgressObserver;
  private userProgressTracker: UserProgressTracker;
  private oneLiner: OneLiner;
  private hasProgressionFailureBeenShown = false;
  private appInsightsTracker: InterfaceOf<AppInsightsTracker>;
  private codeChallengeProvider: CodeChallengeProvider;
  private mediaInformationApplier: MediaInformationApplier;
  private playbackStatsAggregator: PlaybackStatsAggregator;
  private inviteCodeRedeemer: InviteCodeRedeemer;
  private nrkService: NRKService;
  gen1Message: Gen1Message;

  constructor(config: AppConfig) {
    this.config = config;
    this.appInsightsTracker =
      config.instrumentationKey && config.instrumentationKey.length
        ? new AppInsightsTracker(config)
        : new NullAppInsightsTracker();
    this.videoElement = document.querySelector('video') as HTMLVideoElement;
    this.nrkService = this.resolveNRKService();
    this.backgroundLogo = new BackgroundLogo(NrkLogo[this.nrkService]);
    this.sessionState = new SessionState();
    this.sessionState.medium = this.nrkService === NRKService.Radio ? Medium.RADIO : Medium.TV;
    this.updateMediaInformationThrottled = throttle(this.updateMediaInformation, 900);
    this.oneLiner = new OneLiner();
    // Production as well!
    // this.oneLiner.enabled = config.env !== 'prod';
  }

  start() {
    setLogLevel(cast.framework.LoggerLevel.WARNING);
    window.addEventListener('error', (event) => this.appInsightsTracker.trackErrorEvent(event));

    const useShakaForHls = this.config.useShakaForHls;
    logger.info('Use Shaka for HLS:', useShakaForHls);

    this.castReceiverContext = cast.framework.CastReceiverContext.getInstance();
    this.playerManager = this.castReceiverContext.getPlayerManager();
    this.appInsightsTracker.setRecieverContext(this.castReceiverContext);
    this.appInsightsTracker.trackPageView();

    this.hud = new HeadUpDisplay();
    this.spinner = new DummySpinnerOverlay();
    this.errorOverlay = new ErrorOverlay();
    this.gen1Message = new Gen1Message();
    this.posterOverlay = new PosterOverlay();
    this.slideshow = new Slideshow({
      dataURL: this.config.slideshowDataURL,
      slideTime: this.config.slideshowSlideTime,
    });

    this.playbackProgression = new PlaybackProgression(this.playerManager);
    this.playbackProgression.listen(this.onPlaybackProgression);
    this.timeManager = new TimeManager(this.playerManager, useShakaForHls);

    this.seekRequestHandler = new SeekRequestHandler(this.playerManager, this.timeManager);

    this.playerManager.setMessageInterceptor(cast.framework.messages.MessageType.LOAD, this.interceptLoadMessage);
    this.playerManager.setMessageInterceptor(
      cast.framework.messages.MessageType.QUEUE_INSERT,
      this.interceptQueueInsertMessage
    );
    this.playerManager.setMessageInterceptor(
      cast.framework.messages.MessageType.MEDIA_STATUS,
      this.interceptMediaStatus
    );
    this.playerManager.setMessageInterceptor(
      cast.framework.messages.MessageType.EDIT_AUDIO_TRACKS,
      this.interceptEditAudioTracks
    );
    this.playerManager.setMessageInterceptor(cast.framework.messages.MessageType.DISPLAY_STATUS, () => {
      this.hud.toggle();
      return null;
    });

    this.tracker = new NrkMediaLogger(this.videoElement, this.timeManager, this.nrkService);

    this.liveEpgObserver = new LiveEpgObserver(this.playerManager, this.timeManager);
    this.liveEpgObserver.on(EpgTrackerEvents.LIVEPROGRAM_CHANGED, this.onLiveProgramChanged);
    this.liveRadioBehaviour = new LiveRadioBehaviour(this.liveEpgObserver, this.posterOverlay, this.hud);

    this.textTrackHandler = new TextTrackHandler(this.playerManager);
    this.textTrackHandler.listen(() => this.sendSubtitlesState());

    this.customMessageHandler = new CustomMessageHandler(this.castReceiverContext);
    this.customMessageHandler.on(CustomNamespace.COMMAND, this.onCustomCommand);
    this.customMessageHandler.on(CustomNamespace.ERROR, this.onCustomError);

    this.countdownHandler = new CountdownHandler(this.playerManager);

    this.castReceiverContext.addEventListener(cast.framework.system.EventType.READY, this.onReady);
    this.castReceiverContext.addEventListener(cast.framework.system.EventType.SENDER_CONNECTED, (event) => {
      // @ts-expect-error: senderId doesn't exist on type event, but it's there.
      const senderId: string = event.senderId;
      if (!senderId) {
        return;
      }
      const sender = this.castReceiverContext.getSender(senderId);
      this.appInsightsTracker.trackSender(sender);
      this.sendSubtitlesState();
    });

    const playerData = {};
    this.playerDataBinder = new cast.framework.ui.PlayerDataBinder(playerData);

    this.delayLoadMiddleware.addMiddleware(() => this.slideshow.stop());

    this.playerDataBinder.addEventListener(
      cast.framework.ui.PlayerDataEventType.STATE_CHANGED,
      this.onPlayerStateChanged
    );

    this.playerManager.addEventListener(cast.framework.events.EventType.ALL, this.onPlayerManagerEvent);

    this.codeChallengeProvider = new CodeChallengeProvider();
    this.authenticationHandler = new AuthenticationHandler(this.codeChallengeProvider, this.tracker);
    this.inviteCodeRedeemer = new InviteCodeRedeemer(
      this.authenticationHandler,
      this.appInsightsTracker,
      this.customMessageHandler,
      this.oneLiner
    );

    this.errorObserver = new ErrorObserver(this.castReceiverContext);
    this.errorObserver.listen(this.onPlayerError);
    this.errorBehaviour = new ErrorBehaviour(
      this.errorObserver,
      this.customMessageHandler,
      this.errorOverlay,
      this.authenticationHandler
    );

    debugPlayer(this.playerManager, this.playerDataBinder);
    this.userProgressTracker = new UserProgressTracker(this.authenticationHandler);
    this.userProgressObserver = new UserProgressObserver(this.playerManager);
    this.userProgressObserver.listen(this.onUserProgress);

    this.mediaInformationApplier = new MediaInformationApplier(
      this.playbackProgression,
      this.timeManager,
      this.authenticationHandler
    );

    this.playbackStatsAggregator = new PlaybackStatsAggregator(
      this.playerManager,
      this.castReceiverContext,
      this.playerDataBinder
    );
    trackSessionStats(this.playbackStatsAggregator, this.appInsightsTracker);

    // https://developers.google.com/cast/docs/web_receiver/core_features#configure_the_player
    const playbackConfig = Object.assign(new cast.framework.PlaybackConfig(), this.playerManager.getPlaybackConfig());
    playbackConfig.manifestHandler = this.timeManager.manifestHandler;

    if (useShakaForHls) {
      ((playbackConfig.shakaConfig ??= {}).manifest ??= {}).hls ??= {};
      // https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.ManifestConfiguration
      // CAF sets this to 50s for HLS, causing a seekable range of 20s. We want
      // the full live buffer.
      playbackConfig.shakaConfig.manifest.availabilityWindowOverride = Number.NaN;
      // https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.HlsManifestConfiguration
      // ...the seek window will be zero-sized, to be consistent with Safari.
      // If this is false, the seek window will be the entire duration.
      playbackConfig.shakaConfig.manifest.hls.useSafariBehaviorForLive = false;
      // https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.StreamingConfiguration
      // Limit the amount of buffer behind the playhead. Good for low memory devices.
      playbackConfig.shakaConfig.streaming = {
        bufferBehind: 30,
      };
    }

    this.castReceiverContext.start({
      playbackConfig,
      useShakaForHls,
      skipMplLoad: useShakaForHls,
    });
  }

  private onUserProgress = (progress: UserProgress) => {
    // Skip tracking if it keeps failing
    if (this.userProgressTracker.successes === 0 && this.userProgressTracker.failures >= 3) {
      if (!this.hasProgressionFailureBeenShown) {
        this.oneLiner.showMessage('Vi klarer ikke å logge progresjon', { iconHref: '#nrk-warning' });
        this.appInsightsTracker.trackEvent('user-progress-failure');
        this.hasProgressionFailureBeenShown = true;
      }
      return;
    }

    this.userProgressTracker.trackPosition(progress.position, progress.startPlaybackPosition).catch((e) => {
      logger.warn('trackPosition failed', e && e.message);
      if (this.userProgressTracker.failures === 2) {
        this.appInsightsTracker.trackException(e);
      }
    });
  };

  private onReady = () => {
    document.body.classList.remove('loading');
    this.setDeviceSpecificBehaviour();
    this.startSlideshow(true);
    this.appInsightsTracker.setRecieverContext(this.castReceiverContext);
  };

  /*
   * Hide all visual elements on Chromecast Audio devices.
   * Complex rendering on some embedded devices (JBL) will crash the app.
   *
   * Ref. https://developers.google.com/cast/docs/audio#development
   */
  private setDeviceSpecificBehaviour = () => {
    const deviceCapabilities = this.castReceiverContext.getDeviceCapabilities();
    this.sessionState.isVideoDevice = deviceCapabilities.display_supported;
    if (this.sessionState.isVideoDevice) {
      this.hud.isEnabled = true;
      this.spinner = new SpinnerOverlay();
    } else {
      const container = document.querySelector<HTMLElement>('.gcast-container');
      if (container !== null) {
        container.style.display = 'none';
      }
    }

    logger.log('DeviceCapabilities', deviceCapabilities);
  };

  private onPlaybackProgression = (progress: number) => {
    if (this.timeManager.isLive()) {
      const liveCurrentTime = this.timeManager.getAbsoluteTimeForMediaTime(this.timeManager.getCurrentTimeSec());
      const liveEndTime = Math.max(
        liveCurrentTime,
        this.timeManager.getAbsoluteTimeForMediaTime(this.timeManager.getLiveSeekableRange().end ?? 0)
      );

      this.hud.leftTime = dateToTimeString(new Date(liveCurrentTime * 1000));
      this.hud.rightTime = dateToTimeString(new Date(liveEndTime * 1000));
      this.hud.progress = progress;

      // XXX: Should be removed. It forces updates to be sent to clients every
      // 1s. Clients which still use the numbers in customData.live depend on
      // this. Can we treat senders differently?
      this.updateMediaInformationThrottled();
    } else {
      const currentTime = this.playerManager.getCurrentTimeSec();
      const duration = this.playerManager.getDurationSec();

      this.hud.leftTime = secondsToTimeString(currentTime);
      this.hud.rightTime = secondsToTimeString(duration);
      this.hud.progress = progress;
    }
  };

  private onPlayerManagerEvent = (e: cast.framework.events.Event) => {
    switch (e.type) {
      case cast.framework.events.EventType.MEDIA_FINISHED:
        {
          const mediaFinishedEvent = e as cast.framework.events.MediaFinishedEvent;
          if (mediaFinishedEvent.endedReason !== cast.framework.events.EndedReason.INTERRUPTED) {
            this.resetPlayer();
          }

          const startSlideshowReasons = [
            cast.framework.events.EndedReason.END_OF_STREAM,
            cast.framework.events.EndedReason.STOPPED,
          ];
          if (mediaFinishedEvent.endedReason && startSlideshowReasons.includes(mediaFinishedEvent.endedReason)) {
            this.startSlideshow(false);
          }
        }
        break;

      case cast.framework.events.EventType.SEEKED:
        this.updateMediaInformation();
        break;

      case cast.framework.events.EventType.PLAYER_LOAD_COMPLETE:
        {
          const mediaInformation = this.playerManager.getMediaInformation();

          if (mediaInformation.duration) {
            this.hud.rightTime = secondsToTimeString(mediaInformation.duration);
          }

          this.updateMediaInformation();
          this.showPlayer();

          const customData = mediaInformation.customData as MediaInformationCustomData;

          // Add next media to the queue, if any.
          if (customData.nextMedia !== undefined) {
            logger.log('Adding next media to queue wrapped in queue items:', customData.nextMedia);

            for (const nextMedia of customData.nextMedia) {
              const queueItem = new cast.framework.messages.QueueItem();
              queueItem.preloadTime = 20;
              queueItem.media = nextMedia;
              // @ts-expect-error: Bad types. insertItems doesn't require 2 arguments.
              this.playerManager.getQueueManager()?.insertItems([queueItem]);
            }
            customData.nextMedia = undefined;
          }

          this.playerManager.setSupportedMediaCommands(cast.framework.messages.Command.ALL_BASIC_MEDIA, true);
        }
        break;

      case cast.framework.events.EventType.PROGRESS:
      case cast.framework.events.EventType.TIME_UPDATE:
        this.showPlayer();
        break;
    }

    switch (e.type) {
      case cast.framework.events.EventType.REQUEST_LOAD:
      case cast.framework.events.EventType.PLAYER_LOADING:
      case cast.framework.events.EventType.LOAD_START:
      case cast.framework.events.EventType.TIME_UPDATE:
        this.errorBehaviour.stopErrorOverlay();
        break;
    }
  };

  private onPlayerStateChanged = (e: cast.framework.ui.PlayerDataChangedEvent) => {
    let state = HudPlaybackState.IDLE;
    let isWaiting = false;

    switch (e.value) {
      case cast.framework.ui.State.IDLE:
      case cast.framework.ui.State.LAUNCHING:
        state = HudPlaybackState.IDLE;
        break;

      case cast.framework.ui.State.LOADING:
      case cast.framework.ui.State.BUFFERING:
        isWaiting = true;
        break;

      case cast.framework.ui.State.PAUSED:
        state = HudPlaybackState.PAUSED;
        break;

      case cast.framework.ui.State.PLAYING:
        state = HudPlaybackState.PLAYING;
        this.gen1Message.hide();
        break;
    }

    this.toggleSpinner(isWaiting);

    this.hud.playbackState = state;
    this.hud.isWaiting = isWaiting;
  };

  private onCustomCommand = (event: cast.framework.system.CustomMessageEvent, command?: CustomCommands) => {
    switch (command) {
      case CustomCommands.RECEIVER_NAME:
        break;

      case CustomCommands.SET_METADATA:
        if (typeof event.data === 'object' && event.data !== null) {
          const data = event.data as MediaItem;

          this.hud.title = data.title;
          this.hud.description = data.subtitle;
          this.hud.imageUrl = data.images[0]?.imageUrl;

          const context = mapToPlayerContext(data);
          this.reflectContext(context);
        }
        break;

      case CustomCommands.TOGGLE_HUD:
        this.hud.toggle();
        break;

      case CustomCommands.TOGGLE_SUB:
        this.textTrackHandler.toggle();
        break;

      case CustomCommands.SUB_STATUS:
        this.sendSubtitlesState();
        break;

      case CustomCommands.SUBS_ON:
        this.textTrackHandler.toggle(true);
        break;

      case CustomCommands.SUBS_OFF:
        this.textTrackHandler.toggle(false);
        break;

      case CustomCommands.CANCEL_NEXT_EPISODE:
        {
          const queueManager = this.playerManager.getQueueManager();
          if (queueManager === undefined) {
            break;
          }

          const currentIndex = queueManager.getCurrentItemIndex();
          const itemsToRemove = queueManager.getItems().filter((_item, index) => index > currentIndex);
          logger.log('Cancel next episode - removing queue items:', itemsToRemove);

          queueManager.removeItems(
            itemsToRemove.map((item) => item.itemId).filter((id): id is number => typeof id === 'number')
          );
          this.countdownHandler.stop();
        }
        break;

      case CustomCommands.DEBUG_RECEIVER:
        this.customMessageHandler.toggleRemoteDebug();
        break;

      case CustomCommands.REMOTE_CONSOLE:
        debugging.start();
        break;

      case CustomCommands.TRIGGER_ERROR:
        throw new Error('Test error');

      case CustomCommands.REQUEST_CODE_CHALLENGE:
        // Request id added to be able to correlate multiple (quick) code
        // challenge requests from the same sender.
        this.requestCodeChallenge(event.senderId, event.data.requestId);
        break;

      case CustomCommands.REDEEM_INVITE_CODE:
        interface RedeemData {
          inviteCode: string;
          codeChallenge: string;
        }

        {
          const redeemData: RedeemData = event.data;
          this.inviteCodeRedeemer.redeemInviteCode(redeemData.inviteCode, redeemData.codeChallenge, event.data);
        }
        break;

      default:
        throw new Error(`Unknown command "${command}"`);
    }
  };

  private sendSubtitlesState() {
    // The media information can be null, even if it's not typed as such.
    const mediaInformation = this.playerManager.getMediaInformation() as ReturnType<
      cast.framework.PlayerManager['getMediaInformation']
    > | null;
    if (!mediaInformation) {
      return;
    }

    let cmd: CustomCommands;

    if (mediaInformation.streamType === cast.framework.messages.StreamType.LIVE) {
      if (mediaInformation.customData?.hasAllSpeechSubtitles) {
        if (mediaInformation.customData.manifestName === 'default') {
          cmd = CustomCommands.SUB_HIDDEN;
        } else {
          cmd = CustomCommands.SUB_VISIBLE;
        }
      } else {
        cmd = CustomCommands.NO_SUBTITLES;
      }
    } else {
      if (this.textTrackHandler.hasTextTracks()) {
        if (this.textTrackHandler.getActiveTrack() !== undefined) {
          cmd = CustomCommands.SUB_VISIBLE;
        } else {
          cmd = CustomCommands.SUB_HIDDEN;
        }
      } else {
        cmd = CustomCommands.NO_SUBTITLES;
      }
    }

    this.customMessageHandler.sendCommand(cmd);
  }

  private onCustomError(event: cast.framework.system.CustomMessageEvent) {
    logger.error(CustomNamespace.ERROR, event);
  }

  private startSlideshow(delay = false) {
    if (
      !this.config.enableSlideshow ||
      !this.sessionState.isVideoDevice ||
      this.authenticationHandler.contentGroup === 'children'
    ) {
      return;
    }

    const mediumName = this.sessionState.medium === Medium.RADIO ? 'radio' : 'tv';
    const startDelayTime = delay ? this.config.displaySlideShowAfterConnectTime * 1000 : 100;
    this.slideshow.start(mediumName, startDelayTime).catch(logger.log);
  }

  private interceptMediaStatus = (
    mediaStatus: cast.framework.messages.MediaStatus
  ): cast.framework.messages.MediaStatus => {
    if (mediaStatus.media !== undefined) {
      this.mediaInformationApplier.applyTo(mediaStatus.media);
    } else {
      logger.log('Media status without media object:', mediaStatus);
    }

    mediaStatus.currentTime = this.timeManager.getCurrentTimeSec();
    mediaStatus.liveSeekableRange = this.timeManager.getLiveSeekableRange();

    if (mediaStatus.media !== undefined) {
      mediaStatus.media.startAbsoluteTime = this.timeManager.getStartAbsoluteTime();
    }

    return mediaStatus;
  };

  private updateMediaInformation() {
    const mediaInformation = this.playerManager.getMediaInformation();
    this.mediaInformationApplier.applyTo(mediaInformation);
    this.playerManager.setMediaInformation(mediaInformation, true);
  }

  /**
   * Add metadata to inserted queue items.
   */
  private interceptQueueInsertMessage = async (
    queueInsertRequestData: cast.framework.messages.QueueInsertRequestData
  ): Promise<cast.framework.messages.QueueInsertRequestData> => {
    logger.log('Intercepting queue insert request to add metadata:', queueInsertRequestData);

    for (const item of queueInsertRequestData.items) {
      if (item.media !== undefined && item.media.contentId && MediaURI.isValidFormat(item.media.contentId)) {
        let mediaItem;
        try {
          mediaItem = await getMediaItem(
            item.media.contentId,
            [item.media.customData.manifestName ?? 'default'],
            false, // enableLiveSubtitles. Not applicable to series.
            getAuthHeaders(this.authenticationHandler),
            this.authenticationHandler
          );
        } catch (e) {
          logger.error('Failed to load metadata for queue item:', e);
          continue;
        }

        const metadata = new cast.framework.messages.GenericMediaMetadata();
        metadata.title = mediaItem.title;
        metadata.subtitle = mediaItem.subtitle;
        metadata.images = mediaItem.images.map((image) => {
          const castImage = new cast.framework.messages.Image(image.imageUrl);
          castImage.width = image.pixelWidth;
          return castImage;
        });

        item.media.metadata = metadata;
        item.media.duration = mediaItem.duration || undefined;

        logger.log('Added metadata to queue item:', item);
      }
    }

    return queueInsertRequestData;
  };

  private interceptLoadMessage = async (
    loadRequestData: cast.framework.messages.LoadRequestData
  ): Promise<cast.framework.messages.LoadRequestData> => {
    if (loadRequestData.type === 'PRELOAD') {
      logger.log('Ignoring PRELOAD load message:', loadRequestData);
      return loadRequestData;
    }

    // Stringify to snapshot the load request data before modifications.
    logger.log('Intercept load message', JSON.stringify(loadRequestData, undefined, 2));

    delete this.errorObserver.metadata;
    const loadMessageStartTime = new Date();
    this.appInsightsTracker.setLoadRequest(loadRequestData);
    this.userProgressObserver.stop();
    this.userProgressTracker.reset();
    this.hasProgressionFailureBeenShown = false;

    await resolveLoadRequestEntity(loadRequestData);

    // Load requests from client/sender have request ID. Load requests from the
    // queue don't (requestId = 0).
    if (loadRequestData.requestId) {
      this.authenticationHandler.logOut();
      await this.inviteCodeRedeemer.redeemFromLoadRequest(loadRequestData);
    }

    const contentId = loadRequestData.media.contentId;
    if (contentId && MediaURI.isValidFormat(contentId)) {
      // NRK TV on Android has had a bug where they add an empty text track to
      // the load request. Get rid of it before continuing with NRK media,
      // which provides all needed tracks anyway.
      if (loadRequestData.media.tracks !== undefined) {
        delete loadRequestData.media.tracks;
      }

      this.loader = new MediaElementLoader(this.sessionState, this.authenticationHandler);
      this.userProgressObserver.start();
    } else {
      this.loader = new BasicLoader();
    }

    let loadResult: LoadedResult;
    try {
      loadResult = await this.loader.load(loadRequestData);
    } catch (err) {
      const error = err as Error & { metadata?: MediaItem };
      if (error.metadata) {
        this.errorObserver.metadata = error.metadata;
      }
      logger.error(`Failed to load media (origin ${window.location.origin})`, error);
      throw error;
    }

    const { loadRequestData: newLoadRequestData, context, mediaItem, progressLink } = loadResult;

    logger.log('Loaded', context, mediaItem);

    if (loadRequestData.media.contentType.startsWith('application/')) {
      // CORS is only needed for sideloaded subtitles for HLS and DASH streams.
      this.videoElement.setAttribute('crossorigin', 'anonymous');
    } else {
      // Telenor CDN has a CORS bug for requests to "audio/*" type files.
      // Luckly we don't have sideloaded subtitles for those.
      this.videoElement.removeAttribute('crossorigin');
    }

    const metadata = loadRequestData.media.metadata as
      | cast.framework.messages.GenericMediaMetadata
      | cast.framework.messages.MusicTrackMediaMetadata
      | cast.framework.messages.MovieMediaMetadata
      | cast.framework.messages.TvShowMediaMetadata
      | undefined;

    if (metadata !== undefined) {
      this.hud.title = metadata.title;
      if (metadata.images?.length) {
        this.hud.imageUrl = metadata.images[metadata.images.length - 1].url;
      }
      this.hud.description =
        'subtitle' in metadata ? metadata.subtitle : 'songName' in metadata ? metadata.songName : undefined;
    }

    if (this.nrkService === NRKService.TV && getChromecastModel() === 'gen1') {
      this.gen1Message.show();
    }

    this.startTracker(mediaItem);
    this.userProgressTracker.setProgressLink(progressLink);
    this.appInsightsTracker.setMediaItem(mediaItem);
    this.sessionState.updateFromMediaItem(mediaItem);
    await this.delayLoadMiddleware.run();

    this.reflectContext(context);

    newLoadRequestData.customData = newLoadRequestData.customData || {};

    // Some apps might set currentTime to 0 by default. This will make the playback start at buffer start.
    // We need to clear currentTime to make it play from buffer end (live).
    if (
      newLoadRequestData.media.streamType === cast.framework.messages.StreamType.LIVE &&
      newLoadRequestData.currentTime === 0
    ) {
      newLoadRequestData.currentTime = undefined;
    }

    const loadMessageDuration = new Date().getTime() - loadMessageStartTime.getTime();
    setTimeout(() => {
      this.appInsightsTracker.trackEvent('load', undefined, { loadMessageDuration });
    }, 100);

    logger.debug('Done intercepting load request:', JSON.stringify(newLoadRequestData, undefined, 2));

    // Update the queue media.
    const queueManager = this.playerManager.getQueueManager();
    if (queueManager !== undefined) {
      const currentItem = queueManager.getCurrentItem();
      const media = new cast.framework.messages.MediaInformation();
      const updatedMedia = newLoadRequestData.media;
      media.entity = updatedMedia.entity;
      media.contentId = updatedMedia.contentId;
      media.contentUrl = updatedMedia.contentUrl;
      media.contentType = updatedMedia.contentType;
      media.metadata = updatedMedia.metadata;
      media.duration = updatedMedia.duration;
      media.streamType = updatedMedia.streamType;
      media.hlsSegmentFormat = updatedMedia.hlsSegmentFormat;
      media.hlsVideoSegmentFormat = updatedMedia.hlsVideoSegmentFormat;
      // Skip the live and nextMedia custom data.
      media.customData = { ...updatedMedia.customData, live: undefined, nextMedia: undefined };
      currentItem.media = media;
    }
    return newLoadRequestData;
  };

  private resolveNRKService(): NRKService {
    const name = document.location.pathname.replace('/', '');

    if (name === NRKService.Radio) {
      return NRKService.Radio;
    }
    if (name === NRKService.Super) {
      return NRKService.Super;
    }
    return NRKService.TV;
  }

  private toggleSpinner(state: boolean) {
    if (state !== this.spinnerState) {
      this.customMessageHandler.sendCommand(state ? CustomCommands.SHOW_SPINNER : CustomCommands.HIDE_SPINNER);
    }
    if (state) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
    this.spinnerState = state;
  }

  private reflectContext(context: PlayerContext) {
    this.reflectMedium(context.medium);

    this.videoElement.removeAttribute('poster');
    if (context.posterImage && this.sessionState.isVideoDevice) {
      safeLoadPosterImage(context.posterImage)
        .then((posterUrl: string) => {
          this.videoElement.poster = posterUrl;
        })
        .catch((error: Error) => {
          logger.error(error, error.message, 'poster');
        });
    }

    this.showPlayer();
  }

  private reflectMedium(medium: Medium) {
    this.sessionState.medium = medium;
    const isRadio = medium === Medium.RADIO;
    this.hud.isPinned = isRadio;

    if (isRadio && this.sessionState.isVideoDevice) {
      this.hud.show();
      this.liveRadioBehaviour.enable();
    } else {
      this.liveRadioBehaviour.disable();
    }
  }

  private showPlayer() {
    this.errorOverlay.reset();
    this.videoElement.classList.add('video-active');
  }

  private resetPlayer() {
    this.liveRadioBehaviour.disable();
    this.spinner.hide();
    this.hud.reset();

    this.videoElement.removeAttribute('poster');
    this.videoElement.classList.remove('video-active');
  }

  private startTracker(mediaItem?: MediaItem) {
    logger.log('startTracker', mediaItem && mediaItem.id);

    if (mediaItem) {
      this.tracker.prepare(mediaItem);
    } else {
      this.tracker.stop();
    }
  }

  private onPlayerError = (errorDetails: ErrorDetails) => {
    logger.error('onPlayerError', errorDetails);

    const origin = errorDetails.origin;
    const reason = errorDetails.errorData && errorDetails.errorData.reason;
    const error = errorDetails.error instanceof Error ? errorDetails.error : new Error(reason);

    this.appInsightsTracker.trackException(error, false, {
      reason,
      origin,
      stack: error.stack,
    });

    this.slideshow.stop();
  };

  private onLiveProgramChanged = (epgProgramSet: EpgProgramSet) => {
    this.tracker.liveProgramChanged(epgProgramSet);
  };

  private interceptEditAudioTracks = (
    _editAudioTracksRequestData: cast.framework.messages.EditAudioTracksRequestData
  ): null => {
    // Disable feature by returning null
    return null;
  };

  private async requestCodeChallenge(senderId: string, requestId?: string) {
    const codeChallenge = this.codeChallengeProvider.generateCodeChallenge();
    const blob = { codeChallenge, requestId };
    this.customMessageHandler.sendCommand(CustomCommands.CODE_CHALLENGE, blob, senderId);
  }
}
