import { getLogger } from '../logging/logger';
import config from '../appConfig';
import { defaultProfileSettings } from '../defaults';
import { ProfileSettings } from '../psapi/contracts/profileSettings';
import { getAuthHeaders } from '../psapi/getMediaItem';
import { addToCache, getFromCache } from './cache';
import { CodeChallengeProvider } from './CodeChallengeProvider';
import NrkMediaLogger from '../tracking/NrkMediaLogger';

interface RedeemInviteResponse {
  accessToken: string;
  expires: number;
  userName: string;
  userId: string;
}

interface RefreshAccessTokenResponse {
  accessToken: string;
  expires: number;
  userName: string;
}

const logger = getLogger('Auth.Handler');

export class AuthenticationHandler {
  private _accessToken: string | undefined;
  private _expires: number | undefined;
  private _userId: string | undefined;
  private _userName: string | undefined;
  private _refreshTokenTimeoutID: number | undefined;
  private _codeChallengeProvider: CodeChallengeProvider;
  private _nrkMediaLogger: NrkMediaLogger;
  private _contentGroup: string | undefined;
  private _ageRestriction: string | undefined;

  constructor(codeChallengeProvider: CodeChallengeProvider, nrkMediaLogger: NrkMediaLogger) {
    this._codeChallengeProvider = codeChallengeProvider;
    this._nrkMediaLogger = nrkMediaLogger;
  }

  async redeemInviteCode(inviteCode: string, codeChallenge: string, contentId: string | undefined) {
    this.clearState();

    const codeVerifier = this._codeChallengeProvider.redeemCodeChallenge(codeChallenge);
    const data = {
      inviteCode,
      codeVerifier,
    };

    const response = await fetch(config.redeemInviteCodeURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (response.status !== 200) {
      throw new Error('Unexpected response code ' + response.status);
    }

    const json: RedeemInviteResponse = await response.json();
    this._accessToken = json.accessToken;
    this._expires = json.expires;
    this._userName = json.userName;
    this._userId = json.userId;

    this._nrkMediaLogger.setUser(this._userId);
    this.startRefreshAccessTokenTimer();

    await this.fetchProfileSettings(contentId);
    return json;
  }

  async fetchProfileSettings(contentId = 'empty-content-id') {
    try {
      const settingsResponse = await fetch(`${config.profileSettingsURL}/${this._userId}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'NRK-contentId': contentId,
          ...getAuthHeaders(this),
        },
      });

      if (settingsResponse.status !== 200) {
        throw new Error('Invalid response from profilesettings');
      }

      const settingsJson: ProfileSettings = await settingsResponse.json();

      if (settingsJson) {
        this._contentGroup = settingsJson.data['contentGroup'].value;
        this._ageRestriction = settingsJson.data['ageRestriction'].value;
        addToCache(this._userId, settingsJson.data);
      }
      return;
    } catch (error) {
      const cached = getFromCache(this._userId);
      if (cached.contentGroup?.value && cached.ageRestriction?.value) {
        this._contentGroup = cached.contentGroup.value;
        this._ageRestriction = cached.ageRestriction.value;
      } else {
        // fallback when api not available and not cached
        this._contentGroup = defaultProfileSettings.contentGroup.value;
        this._ageRestriction = defaultProfileSettings.ageRestriction.value;
      }
      logger.log('profilesettings call failed, but is ignored. Using cached values. Error:', error);
    }
  }

  logOut() {
    this.clearState();
  }

  get contentGroup() {
    return this._contentGroup;
  }

  get ageRestriction() {
    return this._ageRestriction;
  }

  get accessToken() {
    return this._accessToken;
  }

  get userId() {
    return this._userId;
  }

  get userName() {
    return this._userName;
  }

  private clearState() {
    this._nrkMediaLogger.clearUser();
    this.abortRefreshAccessTokenTimer();

    delete this._accessToken;
    delete this._expires;
    delete this._userId;
    delete this._userName;
    delete this._contentGroup;
    delete this._ageRestriction;
  }

  private abortRefreshAccessTokenTimer() {
    if (this._refreshTokenTimeoutID) {
      window.clearTimeout(this._refreshTokenTimeoutID);
      delete this._refreshTokenTimeoutID;
    }
  }

  private startRefreshAccessTokenTimer() {
    this.abortRefreshAccessTokenTimer();
    if (!this._expires) {
      return;
    }
    // Fetch a minute ahead of expiration
    const expirationTimestamp = this._expires * 1000;
    const oneMinute = 60 * 1000;
    const whenToFetch = expirationTimestamp - oneMinute;
    let delay = whenToFetch - new Date().getTime();
    delay = Math.max(15000, delay); // Don't retry too fast.

    this._refreshTokenTimeoutID = window.setTimeout(() => {
      this.fetchRefreshAccessToken();
    }, delay);
  }

  private async fetchRefreshAccessToken() {
    const response = await fetch(config.refreshAccessTokenURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    switch (response.status) {
      case 200:
        // eslint-disable-next-line no-case-declarations
        const json: RefreshAccessTokenResponse | undefined = await response.json();
        if (json) {
          this._accessToken = json.accessToken;
          this._expires = json.expires;

          this.startRefreshAccessTokenTimer();
          await this.fetchProfileSettings();
        }
        break;

      case 400:
        this.clearState();
        break;

      case 412:
        this.startRefreshAccessTokenTimer();
        break;
    }
  }
}
