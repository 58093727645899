import document from 'global/document';

export default class PosterOverlay {
  private element: HTMLDivElement;

  constructor() {
    const element = document.querySelector<HTMLDivElement>('.poster-overlay');
    if (element === null) {
      throw new Error('.poster-overlay element missing');
    }
    this.element = element;
  }

  setSrc(imageSrc?: string) {
    if (imageSrc) {
      this.element.style.backgroundImage = `url(${imageSrc})`;
    } else {
      this.element.style.backgroundImage = '';
    }
    this.element.classList.toggle('poster-overlay--active', !!imageSrc);
  }
}
