import { config } from './appConfig';
import CafPlayer from './CafPlayer';
import * as debugging from './debugging';
import { getLogger, addLogHandler } from './logging/logger';
import { ConsoleLogHandler } from './logging/console-log-handler';
import { CastDebugLogHandler } from './logging/cast-debug-log-handler';
import { CustomMessageLogHandler } from './logging/custom-message-log-handler';

addLogHandler(new ConsoleLogHandler());

const logger = getLogger('app');
const isChromecastDevice = /CrKey/.test(window.navigator.userAgent);

if (isChromecastDevice) {
  const customMessageLogHandler = new CustomMessageLogHandler();
  addLogHandler(customMessageLogHandler);

  if (config.debugging) {
    customMessageLogHandler.enableRemoteDebug = true;
    addLogHandler(new CastDebugLogHandler());
    debugging.start();
  }

  logger.log('Starting Chromecast sender App', config);

  const cafPlayer = new CafPlayer(config);
  cafPlayer.start();
} else {
  logger.log('Not a chromecast device, not running app');
}
