import { LiveEpgProgram } from './LiveEpgProgram';

export enum EpgTrackerEvents {
  LIVEPROGRAM_CHANGED = 'liveprogram-changed',
  EPG_UPDATED = 'epg-updated',
  STARTED = 'started',
  STOPPED = 'stopped',
}

export default EpgTrackerEvents;

export interface EpgProgramSet {
  previous?: LiveEpgProgram;
  current?: LiveEpgProgram;
  next?: LiveEpgProgram;
}

export interface EpgTrackerEventArgs {
  [EpgTrackerEvents.LIVEPROGRAM_CHANGED]: [EpgProgramSet];
  [EpgTrackerEvents.EPG_UPDATED]: [LiveEpgProgram[]];
  [EpgTrackerEvents.STARTED]: [];
  [EpgTrackerEvents.STOPPED]: [];
}
