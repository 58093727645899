import URLParse from 'url-parse';
import { getLogger } from '../logging/logger';

const logger = getLogger('share-link');

export interface ShareLink {
  mediaId?: string;
  seriesId?: string;
  seasonId?: string;

  /**
   * Seconds. Only valid together with a mediaId.
   */
  startTime?: number;
}

/**
 * Parse the NRK share link format.
 *
 * See:
 * https://confluence.nrk.no/display/spiller/Delingslenker
 * https://github.com/nrkno/nrk-tv-web/blob/main/apps/resolve-share-links/server/routes/se.js
 */
export function parseShareLink(url: string): ShareLink {
  const parseQueryString = true;
  const parsed = new URLParse(url, parseQueryString);

  // Convert empty strings to undefined.
  const mediaId = parsed.query.v || undefined;
  const seriesId = parsed.query.s || undefined;
  const seasonId = parsed.query.ss || undefined;

  let startTime: number | undefined;

  if (mediaId !== undefined && parsed.query.t) {
    try {
      startTime = parseStartTime(parsed.query.t);
    } catch (e: unknown) {
      logger.warn(`Unsupported start time: ${parsed.query.t}`, e);
    }
  }

  return { mediaId, seriesId, seasonId, startTime };
}

/**
 * Parses start time strings like: "1t2m3s", "3m", "120s".
 */
function parseStartTime(startTime: string): number {
  const result = /^(?:(\d+)t)?(?:(\d+)m)?(?:(\d+)s)?$/.exec(startTime);
  if (result === null) {
    throw new Error(`Start time string doesn't match supported patterns.`);
  }

  let totalSeconds = 0;

  // 1: hours, 2: minutes, 3: seconds
  for (let i = 1; i < 4; ++i) {
    if (result[i] !== undefined) {
      totalSeconds += Number.parseInt(result[i], 10) * Math.pow(60, 3 - i);
    }
  }

  return totalSeconds;
}
