import { config } from '../appConfig';
import { httpGetJson } from '../http/httpGetJson';
import { EpgProgram } from './contracts';

export default async (channel: string): Promise<EpgProgram[]> => {
  const channelUrl = config.epgLiveBufferTemplate.replace('{channelId}', channel);

  try {
    return await httpGetJson<EpgProgram[]>(channelUrl);
  } catch (_e) {
    throw new Error('Loading epg data failed');
  }
};
