export default function safeLoadPosterImage(url: string): Promise<string> {
  // Assigning a bad image url to video.poster results in mediaManager throwing a fatal exception
  // (meta data loaded error) and prevents users from playing video

  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = function () {
      resolve(url);
    };
    image.onerror = function (errorEvent) {
      let error: RequestError;
      if (errorEvent instanceof ErrorEvent) {
        error = errorEvent.error;
      } else {
        error = new Error('Failed to load poster image') as RequestError;
      }
      error.url = url;
      reject(error);
    };
    image.src = url;
  });
}

type RequestError = Error & { url: string };
