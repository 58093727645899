import LiveEpgObserver from './LiveEpgObserver';
import EpgTrackerEvents, { EpgProgramSet } from '../NRKLiveEpg/EpgTrackerEvents';
import { LiveEpgProgram } from '../NRKLiveEpg/LiveEpgProgram';
import safeLoadPosterImage from '../http/safeLoadPosterImage';
import HeadUpDisplay from '../ui/HeadUpDisplay';
import PosterOverlay from '../ui/PosterOverlay';
import { getLogger } from '../logging/logger';

const logger = getLogger('LiveRadioBehaviour');

export default class LiveRadioBehaviour {
  private liveEpgHandler: LiveEpgObserver;
  private posterOverlay: PosterOverlay;
  private hud: HeadUpDisplay;
  private enabled = false;

  constructor(liveEpgHandler: LiveEpgObserver, posterOverlay: PosterOverlay, hud: HeadUpDisplay) {
    this.liveEpgHandler = liveEpgHandler;
    this.posterOverlay = posterOverlay;
    this.hud = hud;
    this.liveEpgHandler.on(EpgTrackerEvents.LIVEPROGRAM_CHANGED, this.onLiveProgramChanged);
  }

  enable() {
    this.enabled = true;
  }

  disable() {
    this.enabled = false;
    this.reset();
  }

  private onLiveProgramChanged = (epgProgramSet: EpgProgramSet) => {
    if (!this.enabled) {
      this.reset();
      return;
    }

    const { title, posterImage } = epgProgramSet.current || ({} as LiveEpgProgram);

    if (!posterImage) {
      this.setEpgOverlay(title);
      return;
    }

    safeLoadPosterImage(posterImage)
      .then((posterUrl: string) => {
        this.setEpgOverlay(title, posterUrl);
      })
      .catch((error: Error) => {
        this.setEpgOverlay(title);
        logger.error(error, error.message, 'poster');
      });
  };

  private setEpgOverlay(descriptionOverride?: string, posterUrl?: string) {
    logger.log('EPG overlay', descriptionOverride, posterUrl);
    this.hud.description = descriptionOverride;
    this.posterOverlay.setSrc(posterUrl);
  }

  private reset() {
    this.setEpgOverlay();
  }
}
