import CountdownOverlay, { CountdownProps } from '../ui/CountdownOverlay';
import { getLogger } from '../logging/logger';

const DEFAULT_PRELOAD_TIME_IN_SECONDS = 10;
const logger = getLogger('CountdownHandler');

enum CountdownHandlerState {
  IDLE,
  WAITING,
  COUNTING,
  PAUSED,
}

export default class CountdownHandler {
  private playerManager: cast.framework.PlayerManager;
  private countdownOverlay: CountdownOverlay;
  private duration = 0;
  private currentMediaTime = 0;
  private queueManager: cast.framework.QueueManager;
  private state: CountdownHandlerState = CountdownHandlerState.IDLE;
  private hasReflectedMetadata = false;
  private timerId?: number;

  constructor(playerManager: cast.framework.PlayerManager) {
    this.playerManager = playerManager;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.queueManager = playerManager.getQueueManager()!;

    this.countdownOverlay = new CountdownOverlay();

    this.playerManager.addEventListener(cast.framework.events.EventType.ALL, this.onPlayerManagerEvent);
  }

  private reflectMetadata() {
    if (this.hasReflectedMetadata) {
      return;
    }
    const nextItem = this.queueManager.getItems()[this.queueManager.getCurrentItemIndex() + 1];
    if (!nextItem) {
      logger.log('No nextItem! current id:', this.queueManager.getCurrentItemIndex());
      return;
    }
    const { media } = nextItem;
    if (!media) {
      return;
    }
    const metadata: cast.framework.messages.GenericMediaMetadata =
      media.metadata as cast.framework.messages.GenericMediaMetadata;
    const images = metadata.images;
    const newProps: CountdownProps = {
      title: metadata.title,
      subtitle: metadata.subtitle,
    };
    if (images && images[0]) {
      newProps.imageUrl = images[0].url;
    }
    this.countdownOverlay.update(newProps);
    this.hasReflectedMetadata = true;
  }

  private onPlayerManagerEvent = (event: cast.framework.events.Event) => {
    const { currentMediaTime } = event as cast.framework.events.MediaElementEvent;

    switch (event.type) {
      case cast.framework.events.EventType.REQUEST_LOAD:
        this.stop();
        break;

      case cast.framework.events.EventType.PLAYER_LOADING: {
        const loadEvent = event as cast.framework.events.LoadEvent;
        this.duration = (loadEvent.media && loadEvent.media.duration) || 0;
        this.start();
        break;
      }
      case cast.framework.events.EventType.PLAYER_PRELOADING_CANCELLED:
      case cast.framework.events.EventType.ABORT:
      case cast.framework.events.EventType.EMPTIED:
        this.stop();
        break;

      case cast.framework.events.EventType.PAUSE:
        this.pause();
        break;

      case cast.framework.events.EventType.TIME_UPDATE:
        if (typeof currentMediaTime === 'number') {
          this.setCurrentMediaTime(currentMediaTime);
        }
        break;
    }
  };

  private start() {
    logger.log('START');
    this.state = CountdownHandlerState.WAITING;
  }

  stop() {
    logger.log('STOP');
    this.stopCountdown();
    this.state = CountdownHandlerState.IDLE;
    this.hasReflectedMetadata = false;
    this.countdownOverlay.reset();
  }

  private pause() {
    logger.log('PAUSED');
    this.state = CountdownHandlerState.PAUSED;
    this.stopCountdown();
  }

  private setCurrentMediaTime(currentMediaTime: number) {
    this.currentMediaTime = currentMediaTime;
    if (this.state === CountdownHandlerState.COUNTING) {
      return;
    }
    const remainingTime = this.getRemainingTime();
    if (remainingTime !== null) {
      this.startCountdown();
    }
  }

  private getRemainingTime(): number | null {
    if (!this.duration || !this.currentMediaTime) {
      return null;
    }

    const remainingTime = Math.floor(this.duration - this.currentMediaTime);
    if (remainingTime < 0) {
      return null;
    }
    if (remainingTime > DEFAULT_PRELOAD_TIME_IN_SECONDS) {
      return null;
    }
    return remainingTime;
  }

  private reflectRemainingTime() {
    const remainingTime = this.getRemainingTime();
    this.countdownOverlay.update({
      remaining: remainingTime || 0,
    });
    if (remainingTime === null) {
      this.stop();
    }
  }

  private stopCountdown() {
    if (this.timerId) {
      window.clearInterval(this.timerId);
    }
  }

  private startCountdown() {
    if (this.state === CountdownHandlerState.COUNTING) {
      return;
    }
    this.stopCountdown();
    this.reflectMetadata();
    this.state = CountdownHandlerState.COUNTING;
    logger.log('COUNTING');
    this.reflectRemainingTime();
    this.timerId = window.setInterval(() => this.reflectRemainingTime(), 1000);
  }
}
