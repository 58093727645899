import iso8601 from 'iso8601-duration';

export function secondsToTimeString(timeInSeconds: number): string {
  if (!timeInSeconds) {
    return '00:00';
  }
  const date = new Date(timeInSeconds * 1000);
  const utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  return dateToTimeString(utc, utc.getHours() !== 0);
}

export function iso8601ToSeconds(iso8601string: string): number {
  if (!iso8601string || !iso8601string.length) {
    return 0;
  }
  return iso8601.toSeconds(iso8601.parse(iso8601string));
}

export function dateToTimeString(date: Date, includeHours = true): string {
  const parts = [];
  if (includeHours) {
    parts.push(date.getHours());
  }
  return parts
    .concat([date.getMinutes(), date.getSeconds()])
    .map((val) => (val <= 9 ? `0${val}` : val))
    .join(':');
}
