import { PlaybackStats, PlaybackStatsReport } from './PlaybackStats';
import { SimpleListener } from '../SimpleListener';
import { getLogger } from '../../logging/logger';

const logger = getLogger('PlaybackStatsAggregator');

export class PlaybackStatsAggregator extends SimpleListener<(report: PlaybackStatsReport) => void> {
  private readonly playerManager: cast.framework.PlayerManager;
  private playbackSessionExperience: PlaybackStats;

  constructor(
    playerManager: cast.framework.PlayerManager,
    castReceiverContext: cast.framework.CastReceiverContext,
    playerDataBinder: cast.framework.ui.PlayerDataBinder
  ) {
    super();
    this.playerManager = playerManager;

    this.playbackSessionExperience = new PlaybackStats();
    this.playerManager.addEventListener(cast.framework.events.EventType.ALL, this.onPlayerManagerEvent);
    castReceiverContext.addEventListener(cast.framework.system.EventType.SHUTDOWN, () => this.finishSession());
    playerDataBinder.addEventListener(cast.framework.ui.PlayerDataEventType.STATE_CHANGED, this.onPlayerStateChanged);
  }

  private onPlayerManagerEvent = (e: cast.framework.events.Event) => {
    switch (e.type) {
      case cast.framework.events.EventType.MEDIA_FINISHED:
        this.finishSession((e as cast.framework.events.MediaFinishedEvent).endedReason);
        break;

      case cast.framework.events.EventType.LOAD_START:
        this.playbackSessionExperience = new PlaybackStats();
        break;

      case cast.framework.events.EventType.PLAYER_LOAD_COMPLETE:
        {
          const event: cast.framework.events.LoadEvent = e;
          const media = event.media;
          const duration = media && media.duration;
          if (duration && duration > 0) {
            this.playbackSessionExperience.setDuration(duration);
          }
          this.playbackSessionExperience.setStats(this.playerManager.getStats());
        }
        break;

      case cast.framework.events.EventType.ERROR:
        try {
          this.playbackSessionExperience.error(e as cast.framework.events.ErrorEvent);
        } catch (e) {
          logger.error(e);
        }
        break;

      case cast.framework.events.EventType.BITRATE_CHANGED:
        this.playbackSessionExperience.bitrateChanged(e as cast.framework.events.BitrateChangedEvent);
        this.playbackSessionExperience.setStats(this.playerManager.getStats());
        break;

      case cast.framework.events.EventType.PLAYING:
        this.playbackSessionExperience.playing(this.getPlayerState());
        if (!this.playbackSessionExperience.duration) {
          const durationSec = this.playerManager.getDurationSec();
          if (durationSec > 0) {
            this.playbackSessionExperience.setDuration(durationSec);
          }
        }
        break;

      case cast.framework.events.EventType.STALLED:
        this.playbackSessionExperience.stalled();
        break;

      case cast.framework.events.EventType.SUSPEND:
        this.playbackSessionExperience.suspend();
        break;

      case cast.framework.events.EventType.WAITING:
        this.playbackSessionExperience.waiting();
        break;

      case cast.framework.events.EventType.SEGMENT_DOWNLOADED:
        this.playbackSessionExperience.segmentDownloaded(e);
        break;
    }
  };

  private onPlayerStateChanged = (e: cast.framework.ui.PlayerDataChangedEvent) => {
    if (e.value === cast.framework.ui.State.BUFFERING) {
      this.playbackSessionExperience.startBuffering(this.getPlayerState());
    } else {
      this.playbackSessionExperience.endBuffering(this.getPlayerState());
    }
  };

  private finishSession(endedReason?: cast.framework.events.EndedReason) {
    if (this.playbackSessionExperience.hasFinished) {
      return;
    }
    this.playbackSessionExperience.finished(endedReason);
    this.emit(this.playbackSessionExperience.getReport());
  }

  private getPlayerState() {
    return {
      currentTime: this.playerManager.getCurrentTimeSec(),
    };
  }
}
