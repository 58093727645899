import { LogHandler, LogLevel } from './logger';

export interface ConsoleLogHandlerOptions {
  /**
   * The namespaces are formatted to a fixed width prefix. The number of
   * characters does not include the delimiter. Default 20.
   */
  prefixLength?: number;

  /**
   * A string that is repeated to pad the namespaces into fixed width prefixes.
   * Default: `' '`.
   */
  prefixFiller?: string;

  /**
   * A string that separates the prefix and the log messages. Default: `' |'`.
   */
  prefixDelimiter?: string;

  /**
   * Overrides the detection of console color support.
   */
  color?: boolean;
}

/**
 * Emits log messages to the console, prefixed by the namespaces. The
 * namespaces are formatted to fixed width prefixes, potentially with color.
 *
 * To use the `ConsoleLogHandler`, do:
 *
 * ```ts
 * import { addLogHandler } from './logging/logger';
 * import { ConsoleLogHandler } from './logging/console-log-handler';
 *
 * addLogHandler(new ConsoleLogHandler());
 * ```
 */
export class ConsoleLogHandler implements LogHandler {
  private prefixLength: number;
  private prefixFiller: string;
  private prefixDelimiter: string;
  private color: boolean;
  private goldenRatio = 1.618033988749895;
  private prefixCache: Record<string, string[]> = {};

  constructor(options?: ConsoleLogHandlerOptions) {
    this.prefixLength = options?.prefixLength ?? 20;
    this.prefixFiller = options?.prefixFiller ?? ' ';
    this.prefixDelimiter = options?.prefixDelimiter ?? ' |';
    this.color = options?.color ?? this.isColorSupported();
  }

  log(namespace: string, level: LogLevel, message: unknown, ...messages: unknown[]): void {
    if (!(namespace in this.prefixCache)) {
      const prefix = [this.createPrefix(namespace)];

      if (this.color) {
        prefix[0] = `%c${prefix[0]}`;
        prefix.push(`color:hsl(${this.nextHue() * 360},99%,40%);font-weight:bold;`);
      }

      this.prefixCache[namespace] = prefix;
    }

    console[level](...this.prefixCache[namespace], message, ...messages);
  }

  private createPrefix(namespace: string): string {
    return (
      `${namespace}${Array(this.prefixLength).join(this.prefixFiller)}`.slice(0, this.prefixLength) +
      `${this.prefixDelimiter}`
    );
  }

  private nextHue(): number {
    return Object.keys(this.prefixCache).length * this.goldenRatio;
  }

  private isColorSupported(): boolean {
    if (typeof window !== 'undefined' && 'chrome' in window) {
      return true;
    }

    if (typeof navigator !== 'undefined' && /firefox/i.test(navigator.userAgent)) {
      const m = /Firefox\/(\d+\.\d+)/.exec(navigator.userAgent);
      if (m && m[1] && parseFloat(m[1]) >= 31.0) {
        return true;
      }
    }

    return false;
  }
}
