export type ChangedProps<T extends object> = {
  [K in keyof T]?: { from: T[K]; to: T[K] };
};

export type Observer<T extends object> = (changedProps: ChangedProps<T>) => void;

/**
 * Monitor a set of properties. By default, it takes all the properties it
 * finds on the target object.
 *
 * Could probably be replaced with a Proxy, although the proxy would be outside
 * a class and not inside it.
 */
export class PropsMonitor<T extends object> {
  #target: T;
  #targetProps: Array<keyof T> = [];
  #values = new Map<keyof T, unknown>();
  #changedProps: ChangedProps<T> = {};
  #scheduleNotification?: number;
  #observers: Observer<T>[] = [];

  constructor(target: T, targetProps?: Array<keyof T>) {
    this.#target = target;
    this.#targetProps = targetProps ?? (Object.getOwnPropertyNames(target) as Array<keyof T>);
    this.#targetProps = this.#targetProps.filter((name) => this.#monitorProp(name));
  }

  get monitoredProps(): Array<keyof T> {
    return this.#targetProps;
  }

  addObserver(observer: Observer<T>): void {
    this.#observers.push(observer);
  }

  removeObserver(observer: Observer<T>): void {
    this.#observers = this.#observers.filter((o) => o !== observer);
  }

  destroy(): void {
    this.#observers = [];
    this.#targetProps.forEach((name) => this.#resetProp(name));
    this.#changedProps = {};

    if (this.#scheduleNotification !== undefined) {
      clearTimeout(this.#scheduleNotification);
      this.#scheduleNotification = undefined;
    }
  }

  #monitorProp(name: keyof T): boolean {
    const descriptor = Object.getOwnPropertyDescriptor(this.#target, name);
    if (!descriptor?.configurable || !descriptor.enumerable || !descriptor.writable) {
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    try {
      Object.defineProperty(this.#target, name, {
        configurable: true,
        enumerable: true,
        set(this: T, value: unknown) {
          if (value === self.#values.get(name)) {
            return;
          }
          // @ts-expect-error: Values are unknown but types are expected.
          self.#changedProps[name] = { from: self.#values.get(name), to: value };
          self.#values.set(name, value);
          self.#scheduleNotification ??= window.setTimeout(self.#notifyObservers, 0);
        },
        get(this: T) {
          return self.#values.get(name);
        },
      });
      this.#values.set(name, descriptor.value);
    } catch (_e) {
      return false;
    }
    return true;
  }

  #resetProp(name: keyof T): void {
    Object.defineProperty(this.#target, name, {
      configurable: true,
      enumerable: true,
      writable: true,
      value: this.#values.get(name),
    });
  }

  #notifyObservers = (): void => {
    const changedProps = Object.freeze(this.#changedProps);
    this.#changedProps = {};
    this.#scheduleNotification = undefined;

    for (const observer of this.#observers) {
      observer(changedProps);
    }
  };
}
