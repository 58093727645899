export default class HttpRequestError extends Error {
  url?: string;
  status: string;

  constructor(message: string, { url, status, error }: ErrorDetails = {}) {
    super(message);

    this.name = 'HttpRequestError';
    if (url) {
      this.url = url;
    }
    this.status = `${status}`;

    const stack = [this.stack];

    if (error) {
      stack.push(error.stack);
    }

    this.stack = stack.join('\n');
  }
}

interface ErrorDetails {
  name?: string;
  url?: string;
  status?: number;
  error?: Error;
}
