import EvenEmitter from 'eventemitter3';

import ProgramList from './ProgramList';
import parseEpgLiveBuffer from './parseEpgLiveBuffer';
import loadEpg from './loadEpg';
import { EpgTrackerEvents, EpgTrackerEventArgs } from './EpgTrackerEvents';
import { getLogger } from '../logging/logger';
import { EpgProgram } from './contracts';
import { LiveEpgProgram } from './LiveEpgProgram';

const MINIMUM_RELOAD_RATE = 60000 * 15;
const RELOAD_BEFORE_BUFFER_OUTDATED = 1000;
const RESOLVE_AFTER_PROGRAM_ENDS = 100;

export interface NRKLiveEpgPlayerAdapter {
  currentLiveTime: () => Date;
}

export interface NRKLiveEpgOptions {
  parse?: (data?: EpgProgram[]) => LiveEpgProgram[];
  load?: typeof loadEpg;
  programList?: ProgramList;
  log?: (...args: unknown[]) => void;
}

const logger = getLogger('NRKLiveEpg');

export default class NRKLiveEpg {
  private parse: (data?: EpgProgram[]) => LiveEpgProgram[] = parseEpgLiveBuffer;
  private load: typeof loadEpg = loadEpg;
  private programList: ProgramList = new ProgramList();
  private emitter = new EvenEmitter<EpgTrackerEventArgs>();

  private adapter: NRKLiveEpgPlayerAdapter;

  private currentChannel?: string;
  private isTracking = false;
  private lastProgram: LiveEpgProgram | null = null;
  private reloadTimeoutId?: number;
  private resolveTimeoutId?: number;
  private delayedReloadTimeoutId?: number;
  private lastLoadTime?: number;
  private lastChannel?: string;

  constructor(adapter: NRKLiveEpgPlayerAdapter, options: NRKLiveEpgOptions = {}) {
    this.adapter = adapter;
    Object.assign(this, options);
  }

  start(channel: string) {
    this.clearTimeouts();
    if (!channel) {
      throw new Error('Not a channel');
    }
    this.currentChannel = channel;
    this.isTracking = true;

    this.emit(EpgTrackerEvents.STARTED);
    this.loadPrograms();
  }

  stop() {
    this.isTracking = false;

    this.emit(EpgTrackerEvents.STOPPED);
    this.clearTimeouts();
    this.setEpg([]);
    this.programPlayingEnded();
    this.lastChannel = undefined;
  }

  on<E extends EpgTrackerEvents>(event: E, listener: (...args: EpgTrackerEventArgs[E]) => void) {
    this.emitter.on(event, listener);
  }

  off<E extends EpgTrackerEvents>(event: E, listener: (...args: EpgTrackerEventArgs[E]) => void) {
    this.emitter.off(event, listener);
  }

  resolve() {
    this.resolveProgramPlayingWithSeek();
  }

  private resolveWhenProgramEnds(liveTime: number) {
    const remainingProgramTime = this.programList.getRemainingTime(liveTime);
    if (remainingProgramTime > 0) {
      logger.log(`Resolve when program ends in ${remainingProgramTime / 1000}s`, liveTime);
      if (this.resolveTimeoutId) {
        window.clearTimeout(this.resolveTimeoutId);
      }
      this.resolveTimeoutId = window.setTimeout(() => {
        this.resolveProgramPlaying();
      }, remainingProgramTime + RESOLVE_AFTER_PROGRAM_ENDS);
    }
  }

  private random(number: number) {
    return Math.floor(Math.random() * number);
  }

  private reloadWhenBufferOutdated(liveTime: number) {
    const remainingBufferTime = this.programList.getRemainingBufferTime(liveTime);
    if (remainingBufferTime > 0) {
      logger.log('Reload when buffer ends in', remainingBufferTime / 1000, 's');
      if (this.reloadTimeoutId) {
        window.clearTimeout(this.reloadTimeoutId);
      }
      this.reloadTimeoutId = window.setTimeout(
        () => this.loadPrograms(),
        Math.max(remainingBufferTime - RELOAD_BEFORE_BUFFER_OUTDATED - this.random(120), 0)
      );
    }
  }

  private delayedReload() {
    if (this.delayedReloadTimeoutId) {
      window.clearTimeout(this.delayedReloadTimeoutId);
    }
    this.delayedReloadTimeoutId = window.setTimeout(() => this.loadPrograms(), MINIMUM_RELOAD_RATE - this.random(1000));
  }

  private programPlayingEnded() {
    if (!this.lastProgram) {
      return;
    }

    this.emit(EpgTrackerEvents.LIVEPROGRAM_CHANGED, {});
    this.lastProgram = null;
  }

  private resolveProgramPlaying(withAdditionalLeadTime = false) {
    const liveTime = this.adapter.currentLiveTime().getTime();

    if (this.programList.isOutdated(liveTime)) {
      this.loadPrograms();
      return;
    }

    const programs = this.programList.findByTime(liveTime, withAdditionalLeadTime),
      lastProgramId = this.lastProgram?.programId ?? null,
      currentProgramId = programs.current?.programId ?? null,
      hasChanged = lastProgramId !== currentProgramId;

    if (hasChanged) {
      logger.log('liveprogram-changed', programs);

      this.emit(EpgTrackerEvents.LIVEPROGRAM_CHANGED, programs);
    }

    this.resolveWhenProgramEnds(liveTime);
    this.reloadWhenBufferOutdated(liveTime);

    this.lastProgram = programs.current || null;
  }

  private resolveProgramPlayingWithSeek() {
    this.resolveProgramPlaying(true);
  }

  private clearReloadTimeouts() {
    if (this.reloadTimeoutId) {
      window.clearTimeout(this.reloadTimeoutId);
    }
    if (this.delayedReloadTimeoutId) {
      window.clearTimeout(this.delayedReloadTimeoutId);
    }
  }

  private clearTimeouts() {
    if (this.resolveTimeoutId) {
      window.clearTimeout(this.resolveTimeoutId);
    }
    this.clearReloadTimeouts();
  }

  private setEpg(programs: LiveEpgProgram[] = []) {
    this.programList.set(programs);
    this.emit(EpgTrackerEvents.EPG_UPDATED, programs);
  }

  private loadPrograms() {
    const channel = this.currentChannel;

    if (!channel) {
      this.lastChannel = channel;
      this.clearTimeouts();
      return;
    }

    const now = new Date().getTime();
    if (this.lastChannel === channel && this.lastLoadTime && now - this.lastLoadTime < 15000) {
      this.delayedReload();
      return;
    }
    this.lastChannel = channel;

    this.clearReloadTimeouts();

    this.lastLoadTime = now;

    this.load(channel)
      .then((data) => {
        this.setEpg(this.parse(data));
        this.resolveProgramPlaying();
        this.delayedReload();
      })
      .catch(() => {
        this.delayedReload();
      });
  }

  private emit<E extends EpgTrackerEvents>(event: E, ...args: EpgTrackerEventArgs[E]) {
    // @ts-expect-error: argument length has conflicting types in some constituents
    this.emitter.emit(event, ...args);
  }
}
