import { LogHandler, LogLevel } from './logger';

/**
 * Roughly typed from docs:
 * https://developers.google.com/cast/docs/debugging/cast_debug_logger
 */
declare interface CastDebugLogger {
  debugOverlayElement_: unknown;
  setEnabled(enabled: boolean): void;
  showDebugLogs(show: boolean): void;
  clearDebugLogs(): void;
  [LogLevel.Debug](customTag: string, message: string): void;
  [LogLevel.Warn](customTag: string, message: string): void;
  [LogLevel.Info](customTag: string, message: string): void;
  [LogLevel.Error](customTag: string, message: string): void;
}

declare interface CastDebug {
  debug?: {
    CastDebugLogger: {
      getInstance(): CastDebugLogger;
    };
  };
}

/**
 * Sends logs to the CastDebugLogger, if available.
 *
 * Requires firmware 1.44+.
 * Requires registered device.
 * Requires library:
 *
 *   <script src="//www.gstatic.com/cast/sdk/libs/devtools/debug_layer/caf_receiver_logger.js"></script>
 */
export class CastDebugLogHandler implements LogHandler {
  private castReceiverContext = cast.framework.CastReceiverContext.getInstance();
  private castDebugLogger = (cast as CastDebug).debug?.CastDebugLogger.getInstance();

  constructor() {
    if (this.castReceiverContext.getSystemState() === cast.framework.system.SystemState.READY) {
      this.enabled = true;
    } else {
      this.castReceiverContext.addEventListener(cast.framework.system.EventType.READY, () => {
        this.enabled = true;
      });
    }
  }

  set enabled(on: boolean) {
    if (this.castDebugLogger === undefined) {
      return;
    }
    if (on) {
      if (!this.castDebugLogger.debugOverlayElement_) {
        this.castDebugLogger.setEnabled(true);
        this.castDebugLogger.showDebugLogs(true);
      }
    } else {
      this.castDebugLogger.showDebugLogs(false);
      this.castDebugLogger.setEnabled(false);
    }
  }

  get enabled(): boolean {
    return this.castDebugLogger !== undefined && !!this.castDebugLogger.debugOverlayElement_;
  }

  log(namespace: string, level: LogLevel, message: unknown, ...messages: unknown[]): void {
    const cmd = level === LogLevel.Log ? LogLevel.Debug : level;

    this.castDebugLogger?.[cmd](namespace, `${message}${messages.length > 0 ? ' ' : ''}${messages.join(' ')}`);
  }
}
