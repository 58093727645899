import document from 'global/document';
import { bind as hyper, BoundTemplateFunction } from 'hyperhtml';
import { NrkLogo } from './NrkLogo';
import { PropsMonitor } from '../props-monitor';

export const HIDE_DELAY = 5000;

export enum HudPlaybackState {
  IDLE,
  PLAYING,
  PAUSED,
}

export default class HeadUpDisplay {
  #hudContainerElem: HTMLElement;
  #renderHyper: BoundTemplateFunction<HTMLElement>;
  #delayHideTimeout?: number;
  #props: PropsMonitor<typeof this>;

  logo?: NrkLogo;
  title?: string;
  description?: string;
  imageUrl?: string;

  /**
   * Only enable for video devices. Non-video devices might crash with lots of
   * UI.
   */
  isEnabled = false;
  isPinned = false;
  isWaiting = false;
  leftTime?: string;
  rightTime?: string;
  progress = 0;
  playbackState = HudPlaybackState.IDLE;

  constructor() {
    const hudContainerElem = document.querySelector<HTMLElement>('.hud');
    if (hudContainerElem === null) {
      throw new Error('.hud element missing');
    }

    this.#hudContainerElem = hudContainerElem;
    this.#renderHyper = hyper(this.#hudContainerElem);

    // Alternatively, wrap the hud instance in a proxy which calls render and
    // pass the proxy around instead of the hud instance.
    this.#props = new PropsMonitor(this);
    this.#props.addObserver((changedProps) => {
      this.render(Object.keys(changedProps));
    });
  }

  private render(changedProps: string[] = []) {
    if (!this.isEnabled) {
      return;
    }

    const mustHide = this.title === undefined;

    // Pinned, waiting or state changed.
    const shouldShow =
      this.isPinned ||
      this.isWaiting ||
      (changedProps.includes('playbackState') && this.playbackState !== HudPlaybackState.IDLE);

    // Not pinned or waiting and there's no timeout.
    const shouldHide = !this.isPinned && !this.isWaiting && this.#delayHideTimeout === undefined;

    if (mustHide && this.isShowing) {
      this.hide();
    } else if (shouldShow && !this.isShowing) {
      this.show();
    } else if (shouldHide && this.isShowing) {
      this.hide();
    }

    const width = this.progress.toFixed(1);
    const progressCss = { width: `${width}%` };
    const isPaused = this.playbackState === HudPlaybackState.PAUSED;
    const stateIcon = isPaused ? '#nrk-media-pause' : '#nrk-media-play';

    let playerStateClasses = 'hud__player-state';
    playerStateClasses += isPaused ? ' hud__player-state--pinned' : '';

    this.#renderHyper`
    <div class="${playerStateClasses}">
      <svg class="hud__player-state-icon"><use xlink:href="${stateIcon}" /></svg>
    </div>
    <div class="hud__content">
      <div class="hud__text">
        <img class="hud__poster" src="${this.imageUrl ?? ''}">
        <div class="hud__program-metadata">
          <h1 class="hud__title">${this.title ?? ''}</h1>
          <h2 class="hud__episode">${this.description ?? ''}</h2>
        </div>
        <svg class="hud__logo"><use xlink:href="${this.logo}"/></svg>
      </div>
      <div class="hud__progress">
        <span class="hud__time hud__time__current">${this.leftTime}</span>
        <div class="hud__slider hud__slider__background">
          <div class="hud__slider-track hud__slider__fill" style="${progressCss}"></div>
        </div>
        <span class="hud__time hud__time__total">${this.rightTime}</span>
      </div>
    </div>
  `;
  }

  toggle(): void {
    this.isPinned = !this.isPinned;
  }

  show(): void {
    this.#hudContainerElem.classList.add('hud--active');

    if (!this.isPinned) {
      this.#hideAfterDelay();
    }
  }

  hide(): void {
    this.#clearHideTimeout();

    if (this.isPinned || this.isWaiting) {
      return;
    }

    this.#hudContainerElem.classList.remove('hud--active');
  }

  get isShowing(): boolean {
    return this.#hudContainerElem.classList.contains('hud--active');
  }

  reset(): void {
    this.#clearHideTimeout();
    this.isPinned = false;
    this.isWaiting = false;
    this.title = undefined;
    this.description = undefined;
    this.imageUrl = undefined;
    this.leftTime = undefined;
    this.rightTime = undefined;
    this.progress = 0;
    this.playbackState = HudPlaybackState.IDLE;
  }

  #hideAfterDelay(): void {
    this.#clearHideTimeout();
    this.#delayHideTimeout = window.setTimeout(() => this.hide(), HIDE_DELAY);
  }

  #clearHideTimeout(): void {
    if (this.#delayHideTimeout !== undefined) {
      clearTimeout(this.#delayHideTimeout);
      this.#delayHideTimeout = undefined;
    }
  }
}
