import { AuthenticationHandler } from './AuthenticationHandler';
import config from '../appConfig';

/*
 * Posts the updated program position to PSAPI with the required authentication applied.
 *
 * Ex.https://psapi.nrk.no/tv/userdata/{userId}/progress/programs/{programId}
 * Request body
 * {
 *   position: {iso 8601 duration},
 *   startPlaybackPosition: {iso 8601 duration}
 * }
 */
export class UserProgressTracker {
  private authenticationHandler: AuthenticationHandler;
  private successCounter = 0;
  private failureCounter = 0;
  private progressLink: string | undefined;

  constructor(authenticationHandler: AuthenticationHandler) {
    this.authenticationHandler = authenticationHandler;
  }

  async trackPosition(position: number, startPlaybackPosition: number) {
    const progressLink = this.progressLink;
    if (!progressLink) {
      return;
    }
    const accessToken = this.authenticationHandler.accessToken;
    if (!accessToken) {
      return;
    }
    try {
      await this.updateUserDataProgress(position, startPlaybackPosition);
      this.successCounter++;
    } catch (e) {
      this.failureCounter++;
      throw e;
    }
  }

  setProgressLink(progressLink?: string) {
    this.progressLink = progressLink;
  }

  reset() {
    delete this.progressLink;
    this.successCounter = 0;
    this.failureCounter = 0;
  }

  get failures() {
    return this.failureCounter;
  }

  get successes() {
    return this.successCounter;
  }

  private async updateUserDataProgress(position: number, startPlaybackPosition: number) {
    const accessToken = this.authenticationHandler.accessToken;
    const userId = this.authenticationHandler.userId;
    if (!this.progressLink || !userId) {
      return;
    }
    const userDataProgressLink = this.progressLink.replace('{userId}', userId);
    const url = `${config.apiBase}${userDataProgressLink}`;
    const authentication = `Bearer ${accessToken}`;
    const positionDuration = generateIso8601String(position);
    const startPlaybackPositionDuration = generateIso8601String(startPlaybackPosition);
    const bodyData = {
      position: positionDuration,
      startPlaybackPosition: startPlaybackPositionDuration,
    };

    const response = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(bodyData),
      headers: {
        Authorization: authentication,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 204) {
      // Status code 204 is the only successful response
      return;
    }

    throw new Error(await response.text());
  }
}

export function generateIso8601String(seconds: number) {
  let sec = seconds;
  const parts: string[] = [];
  ['H', 'M', 'S'].map((key, i) => {
    const div = Math.pow(60, 2 - i);
    const val = Math.floor(sec / div);
    sec = sec - val * div;
    if (val || (key === 'S' && !parts.length)) {
      parts.push(`${val}${key}`);
    }
  });
  return `PT${parts.join('')}`;
}
