import { NrkLogo } from './NrkLogo';
import document from 'global/document';
import { bind as hyper, BoundTemplateFunction } from 'hyperhtml';

export default class BackgroundLogo {
  private render: BoundTemplateFunction<HTMLElement>;
  private logoContainerElem: HTMLElement;

  constructor(icon: NrkLogo = NrkLogo.nrk) {
    const logoContainerElem = document.querySelector<HTMLElement>('.background-logo');
    if (logoContainerElem === null) {
      throw new Error('.background-logo element missing');
    }
    this.logoContainerElem = logoContainerElem;
    this.render = hyper(this.logoContainerElem);

    this.update(icon);
  }

  update(icon: NrkLogo = NrkLogo.nrk) {
    this.render`<svg><use xlink:href="${icon}"/></svg>`;
  }
}
