const liveVideoDelay = 51;

export default class PlaybackProgression {
  private playerManager: cast.framework.PlayerManager;
  private listeners: Array<(progress: number) => unknown> = [];

  private streamType?: cast.framework.messages.StreamType;
  private initialStartTime = 0;
  private seekableDuration = 0;
  private intervalID?: number;

  constructor(playerManager: cast.framework.PlayerManager) {
    this.playerManager = playerManager;

    this.onPlayerManagerEvent = this.onPlayerManagerEvent.bind(this);
    this.bind();
  }

  listen(listener: (progress: number) => unknown) {
    this.listeners.push(listener);
  }

  unlisten(listener: (progress: number) => unknown) {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  getDateTimeByProgress(progress: number) {
    const secondsBehind = ((100 - progress) / 100) * this.seekableDuration;
    return new Date(new Date().getTime() - secondsBehind * 1000 - liveVideoDelay * 1000);
  }

  getTimeByDate(date: Date) {
    const time = (date.getTime() - this.initialStartTime) / 1000;
    return time + this.seekableDuration + liveVideoDelay;
  }

  private bind() {
    this.playerManager.addEventListener(cast.framework.events.EventType.ALL, this.onPlayerManagerEvent);
  }

  private onPlayerManagerEvent(e: cast.framework.events.Event) {
    const { type } = e;
    //const { currentMediaTime } = e as cast.framework.events.MediaElementEvent;

    switch (type) {
      case cast.framework.events.EventType.PLAYER_LOADING:
        break;

      case cast.framework.events.EventType.PLAYER_LOAD_COMPLETE: {
        const loadEvent = e as cast.framework.events.LoadEvent;
        const media = loadEvent.media;
        if (media) {
          this.streamType = media.streamType;
        }

        if (this.isLive) {
          this.initialStartTime = new Date().getTime();
          this.seekableDuration = this.getLiveDuration();
        } else {
          this.initialStartTime = 0;
          if (media && typeof media.duration === 'number') {
            this.seekableDuration = media.duration;
          }
        }
        this.startDetection();
        break;
      }
      case cast.framework.events.EventType.MEDIA_FINISHED:
        this.stopDetection();
        break;
    }
  }

  private getLiveDuration() {
    const liveSeekableRange = this.playerManager.getLiveSeekableRange();
    if (
      !liveSeekableRange ||
      typeof liveSeekableRange.end !== 'number' ||
      typeof liveSeekableRange.start !== 'number'
    ) {
      return 0;
    }
    const duration = liveSeekableRange.end - liveSeekableRange.start;
    return duration > 0 ? duration : 0;
  }

  private stopDetection() {
    if (this.intervalID) {
      window.clearInterval(this.intervalID);
    }
  }

  private startDetection() {
    this.stopDetection();
    this.intervalID = window.setInterval(() => this.detectProgressChange(), 1000);
    this.detectProgressChange();
  }

  private detectProgressChange() {
    const progress = this.progress;
    this.listeners.forEach((listener) => listener(progress));
  }

  get timeSinceLiveStarted(): number {
    if (!this.initialStartTime) {
      return 0;
    }
    return (new Date().getTime() - this.initialStartTime) / 1000;
  }

  get currentTime(): number {
    return this.playerManager.getCurrentTimeSec();
  }

  get isLive(): boolean {
    return this.streamType === cast.framework.messages.StreamType.LIVE;
  }

  get progress(): number {
    const progress = this.isLive ? this.liveProgress : this.bufferedProgress;
    return Math.max(0, Math.min(progress, 100));
  }

  get currentLiveTime(): Date {
    return this.getDateTimeByProgress(this.progress);
  }

  get startOfBufferTime(): Date {
    return this.getDateTimeByProgress(0);
  }

  get endOfBufferTime(): Date {
    return this.getDateTimeByProgress(100);
  }

  get duration(): number {
    return this.seekableDuration;
  }

  get startOfBufferInitialTime(): number {
    return this.initialStartTime - this.seekableDuration * 1000;
  }

  get relativeCurrentTime(): number {
    return this.currentTime - this.timeSinceLiveStarted;
  }

  private get liveProgress(): number {
    return (this.relativeCurrentTime / this.seekableDuration) * 100;
  }

  private get bufferedProgress(): number {
    return (this.currentTime / this.seekableDuration) * 100;
  }
}
