/**
 * Internal mini logging framework which emits nothing by default.
 *
 * The log framework supports the standard log levels (debug, log, info, warn,
 * error), and is used as follows:
 *
 * ```ts
 * import { getLogger } from './logging/logger';
 *
 * const logger = getLogger('myModule');
 *
 * logger.log('Hello');
 * ```
 *
 * The log calls are handled by a LogHandler implementation. For example, to
 * use the `ConsoleLogHandler`, do:
 *
 * ```ts
 * import { addLogHandler } from './logging/logger';
 * import { ConsoleLogHandler } from './logging/console-log-handler';
 *
 * addLogHandler(new ConsoleLogHandler());
 * ```
 */

/**
 */
export enum LogLevel {
  Debug = 'debug',
  Log = 'log',
  Info = 'info',
  Warn = 'warn',
  Error = 'error',
}

export interface Logger {
  debug: LogMethod;
  log: LogMethod;
  info: LogMethod;
  warn: LogMethod;
  error: LogMethod;
  metric: LogMetric;
}

export type LogMethod = (message: unknown, ...messages: unknown[]) => void;
export type LogMetric = (name: string, value: number) => void;

export interface LogHandler {
  log(namespace: string, level: LogLevel, message: unknown, ...messages: unknown[]): void;
}

const logHandlers: LogHandler[] = [];

export function addLogHandler(handler: LogHandler): void {
  logHandlers.push(handler);
}

export function removeLogHandler(handler: LogHandler): void {
  const index = logHandlers.indexOf(handler);
  if (index !== -1) {
    logHandlers.splice(index, 1);
  }
}

export function clearAllLogHandlers(): void {
  logHandlers.length = 0;
}

export function getLogger(namespace: string): Logger {
  // The proxy is bound, but the current log handler isn't and can be changed
  // at any time.
  function proxy(namespace: string, level: LogLevel, msg: unknown, ...msgs: unknown[]): void {
    logHandlers.forEach((handler) => handler.log(namespace, level, msg, ...msgs));
  }

  return {
    [LogLevel.Debug]: proxy.bind(proxy, namespace, LogLevel.Debug),
    [LogLevel.Log]: proxy.bind(proxy, namespace, LogLevel.Log),
    [LogLevel.Info]: proxy.bind(proxy, namespace, LogLevel.Info),
    [LogLevel.Warn]: proxy.bind(proxy, namespace, LogLevel.Warn),
    [LogLevel.Error]: proxy.bind(proxy, namespace, LogLevel.Error),
    metric: proxy.bind(proxy, 'Metric', LogLevel.Log),
  };
}

export async function logMetric(logger: Logger, name: string, action: () => Promise<void> | void): Promise<void> {
  const start = Date.now();

  try {
    await action();

    const end = Date.now();
    logger.metric(name, end - start);
  } catch (e) {
    const end = Date.now();
    logger.metric(`${name}-error`, end - start);
    throw e;
  }
}
