import { PlaybackStatsAggregator } from './PlaybackStatsAggregator';
import { AppInsightsTracker } from '../AppInsightsTracker';
import takeRight from 'lodash-es/takeRight';
import { getLogger } from '../../logging/logger';

const logger = getLogger('trackSessionStats');

export function trackSessionStats(
  playbackStatsTracker: PlaybackStatsAggregator,
  appInsightsTracker: InterfaceOf<AppInsightsTracker>
) {
  playbackStatsTracker.listen((report) => {
    const { buffering, stalled, suspend, waiting, errors, bitrates, sessionDuration, durationSec, playingStartupTime } =
      report;
    const bufferingMin = millisec(buffering.min);
    const bufferingMax = millisec(buffering.max);
    const bufferingAvg = millisec(buffering.avg);
    const bufferingSum = millisec(buffering.sum);
    const bufferingCount = buffering.count;

    const metrics: Record<string, number> = {
      bufferingMin,
      bufferingMax,
      bufferingAvg,
      bufferingSum,
      bufferingCount,
      stalledCount: stalled,
      suspendCount: suspend,
      waitingCount: waiting,
      errorCount: errors.length,
      bitrateSwitchCount: bitrates.length,
      sessionDuration: millisec(sessionDuration),
      mediaDuration: millisec(durationSec * 1000),
      playingStartup: millisec(playingStartupTime),
    };

    if (report.streamBandwidth) {
      metrics.streamBandwidth = report.streamBandwidth;
    }

    if (report.downloadSpeed) {
      metrics.downloadSpeed = report.downloadSpeed;
    }

    const network = getNetworkInfo();
    if (network) {
      metrics.networkBandwidth = network.downlink;
    }

    const properties = {
      endReason: report.endedReason,
      errors: JSON.stringify(takeRight(errors, 5)),
      bitrates: JSON.stringify(takeRight(bitrates, 5)),
    };

    logger.log('session-stats', properties, metrics);
    appInsightsTracker.trackEvent('session-stats', properties, metrics);
  });
}

function millisec(milliseconds: number) {
  return Math.floor(milliseconds);
}

// https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation
interface NetworkInformation {
  effectiveType: 'slow-2g' | '2g' | '3g' | '4g';
  rtt: number;
  downlink: number;
  saveData: boolean;
}

function getNetworkInfo() {
  const navigator: Navigator & { connection?: NetworkInformation } = window.navigator;
  return navigator.connection;
}
