import document from 'global/document';

const svgNS = 'http://www.w3.org/2000/svg';
const xlinkNS = 'http://www.w3.org/1999/xlink';

const insertMessageClass = 'insert';
const removeMessageClass = 'remove';

interface MessageOptions {
  delay?: number;
  iconHref?: string;
}

export class OneLiner {
  enabled = true;
  private containerElem: HTMLElement;

  constructor() {
    const containerElem = document.querySelector<HTMLElement>('.oneliners');
    if (containerElem === null) {
      throw new Error('.oneliners element missing');
    }
    this.containerElem = containerElem;

    // Process lines which are done animating.
    this.containerElem.addEventListener('animationend', (event) => {
      const target = event.target;
      if (target instanceof HTMLElement && target.parentNode === this.containerElem) {
        if (target.classList.contains(insertMessageClass)) {
          target.classList.remove(insertMessageClass);
        }
        if (target.classList.contains(removeMessageClass)) {
          this.containerElem.removeChild(target);
        }
      }
    });
  }

  showMessage(message: string, options: MessageOptions = {}) {
    if (!this.enabled) {
      return;
    }

    const { delay = 3000, iconHref } = options;
    const lineElement = document.createElement('div');
    lineElement.classList.add('oneliners_line', insertMessageClass);

    if (iconHref) {
      const iconElement = document.createElementNS(svgNS, 'svg');
      const useElement = document.createElementNS(svgNS, 'use');
      useElement.setAttributeNS(xlinkNS, 'href', iconHref);
      iconElement.appendChild(useElement);
      lineElement.appendChild(iconElement);
    }

    const textElement = document.createElement('span');
    textElement.textContent = message;
    lineElement.appendChild(textElement);

    this.containerElem.appendChild(lineElement);

    setTimeout(() => {
      lineElement.classList.add(removeMessageClass);
    }, delay);
  }
}
