import { LogHandler, LogLevel } from './logger';
import { CustomNamespace } from '../CafPlayer/CustomMessageHandler';

/**
 * Sends logs as custom messages on the debug and error channels.
 */
export class CustomMessageLogHandler implements LogHandler {
  private castReceiverContext = cast.framework.CastReceiverContext.getInstance();

  enableRemoteDebug = false;

  log(namespace: string, level: LogLevel, message: unknown, ...messages: unknown[]): void {
    const senderId = undefined;

    if (this.enableRemoteDebug) {
      this.castReceiverContext.sendCustomMessage(CustomNamespace.DEBUG, senderId, {
        namespace,
        level,
        messages: [message, ...messages],
      });
    }

    if (level === LogLevel.Error) {
      const data = [message, ...messages];
      const errorIndex = data.findIndex((d) => isError(d));
      const error = errorIndex !== -1 ? data[errorIndex] : message;

      this.castReceiverContext.sendCustomMessage(CustomNamespace.ERROR, senderId, {
        namespace,
        error: stringify(error),
        message: data.map((m) => stringify(m)).join('|'),
      });
    }
  }
}

/**
 * Returns true if the argument structurally matches an Error object.
 */
export function isError(e: unknown): e is Error {
  return typeof e === 'object' && e !== null && 'name' in e && 'message' in e;
}

/**
 * Returns true if the argument is a MediaError (which is not an Error object).
 */
export function isMediaError(e: unknown): e is MediaError {
  return (
    typeof e === 'object' &&
    e !== null &&
    (e as Record<string, unknown>).name === undefined &&
    (e as Record<string, unknown>).MEDIA_ERR_ABORTED === 1 &&
    typeof (e as Record<string, unknown>).code === 'number'
  );
}

/**
 * Serialize an object as JSON.
 */
export function stringify(e: unknown): string {
  if (typeof e !== 'object' || e === null) {
    return `${e}`;
  }

  const plainObject = toPlainObject(e as Record<string, unknown>);
  return JSON.stringify(plainObject);
}

/**
 * Recursively convert objects into plain objects. Designed with built-in
 * errors objects in mind (before cause was explicitly typed to Error), which
 * can't always be serialized directly.
 */
function toPlainObject(obj: Record<string, unknown>): Record<string, unknown> {
  const result: Record<string, unknown> = {};

  Object.getOwnPropertyNames(obj)
    .concat(['name', 'message', 'stack', 'msExtendedCode'])
    .filter((prop) => !/^(\d+|[A-Z_]+)$/.exec(prop))
    .filter((prop) => obj[prop] !== undefined)
    .forEach((prop) => {
      if (typeof obj[prop] === 'object' && obj[prop] !== null) {
        result[prop] = toPlainObject(obj[prop] as Record<string, unknown>);
      } else {
        result[prop] = obj[prop];
      }
    });

  // Special handling of MediaError, which doesn't have a name.
  if (isMediaError(obj)) {
    result.name = 'MediaError';
  }

  return result;
}
