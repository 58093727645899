import document from 'global/document';

export default class Gen1Message {
  private element: HTMLDivElement;

  constructor() {
    const element = document.querySelector<HTMLDivElement>('.gen1-message');
    if (element === null) {
      throw new Error('.poster-overlay element missing');
    }
    this.element = element;
  }

  show() {
    this.element.classList.remove('hidden');
    this.element.classList.add('show');
  }

  hide() {
    this.element.classList.remove('show');
    this.element.classList.add('hide');
  }
}
