import { Medium } from './loaders/Loader';
import type { MediaItem } from '../../../types/nrk/mediaitem';

export default class SessionState {
  medium: Medium = Medium.TV;
  isVideoDevice = false;
  preferredManifest?: 'synstolk' | 'tegntolk' | 'default';

  updateFromMediaItem(mediaItem: MediaItem | undefined) {
    if (mediaItem && mediaItem.manifestName) {
      // @ts-expect-error: manifestName is string. preferredManifest is typed above.
      this.preferredManifest = mediaItem.manifestName;
    }
  }
}
