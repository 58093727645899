export default function preloadImage(src: string): Promise<Event> | undefined {
  if (src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = resolve;
      img.onerror = reject;
      img.src = src;
    });
  }
  return undefined;
}
