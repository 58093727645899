import Base64 from 'crypto-js/enc-base64';
import SHA256 from 'crypto-js/sha256';
import { v4 as uuidv4 } from 'uuid';

export interface CodeChallengeRecord {
  timestamp: Date;
  codeVerifier: string;
}

interface CodeChallengeStore {
  [codeChallenge: string]: CodeChallengeRecord | undefined;
}

function getBase64UrlEncodedCodeChallenge(codeVerifier: string) {
  return Base64.stringify(SHA256(codeVerifier)).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export class CodeChallengeProvider {
  private codeChallenges: CodeChallengeStore = {};

  generateCodeChallenge() {
    const codeVerifier = uuidv4();
    const codeChallenge = getBase64UrlEncodedCodeChallenge(codeVerifier);
    const timestamp = new Date();

    this.codeChallenges[codeChallenge] = {
      timestamp,
      codeVerifier,
    };

    return codeChallenge;
  }

  redeemCodeChallenge(codeChallenge: string) {
    const record = this.codeChallenges[codeChallenge];
    if (!record) {
      throw new Error('Code challenge not found');
    }
    this.codeChallenges[codeChallenge] = undefined;
    return record && record.codeVerifier;
  }
}
