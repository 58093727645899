import { HlsMediaPlaylist, HlsMediaPlaylistParser } from './hls-media-playlist-parser';
import { getLogger } from '../logging/logger';

const logger = getLogger('TimeManager');

/**
 * Wraps the most used time methods from PlayerManager and provides corrections
 * for HLS streams.
 */
export class TimeManager {
  #playerManager: cast.framework.PlayerManager;
  #hlsMediaPlaylistParser: HlsMediaPlaylistParser;
  #useShakaForHls: boolean;
  #overrideLiveSeekableRange = false;
  #parsingPaused = false;
  #currentTimeOffset = 0;

  constructor(playerManager: cast.framework.PlayerManager, useShakaForHls: boolean) {
    this.#playerManager = playerManager;
    this.#hlsMediaPlaylistParser = new HlsMediaPlaylistParser();
    this.#useShakaForHls = useShakaForHls;

    // Reset on media finish.
    this.#playerManager.addEventListener(cast.framework.events.EventType.MEDIA_FINISHED, () => {
      logger.log('Resetting.');
      this.#overrideLiveSeekableRange = false;
      this.#parsingPaused = false;
      this.#currentTimeOffset = 0;
    });

    // Update current time offset on seek.
    this.#playerManager.addEventListener(cast.framework.events.EventType.SEEKING, () => {
      if (this.#overrideLiveSeekableRange) {
        const liveSeekableRange = this.#playerManager.getLiveSeekableRange();
        const hlsPlaylist = this.#hlsMediaPlaylistParser.hlsMediaPlaylist;

        if (hlsPlaylist !== undefined && liveSeekableRange !== undefined && liveSeekableRange !== null) {
          this.#currentTimeOffset = hlsPlaylist.start - (liveSeekableRange.start ?? 0);
          logger.log('Updating currentTimeOffset to:', this.#currentTimeOffset);
        }
      }
    });
  }

  isLive(): boolean {
    return this.#playerManager.getMediaInformation()?.streamType === cast.framework.messages.StreamType.LIVE;
  }

  getCurrentTimeSec(): number {
    return this.#playerManager.getCurrentTimeSec() + this.#currentTimeOffset;
  }

  /**
   * Returns the difference between playerManager.getCurrentTimeSec() and
   * proper current time (which is relative to proper position 0 in a live
   * stream).
   */
  getCurrentTimeOffset(): number {
    return this.#currentTimeOffset;
  }

  getStartAbsoluteTime(): number {
    const hlsPlaylist = this.#hlsMediaPlaylistParser.hlsMediaPlaylist;

    if (hlsPlaylist !== undefined) {
      if (hlsPlaylist.programTime !== undefined) {
        return hlsPlaylist.programTime - hlsPlaylist.start;
      }
      // Rough estimate for live streams without EXT-X-PROGRAM-DATE-TIME.
      const delay = hlsPlaylist.targetDuration * 2;
      return Date.now() / 1000 - hlsPlaylist.end - delay;
    }

    return this.#playerManager.getStartAbsoluteTime();
  }

  getLiveSeekableRange(): cast.framework.messages.LiveSeekableRange {
    if (this.#overrideLiveSeekableRange) {
      const hlsPlaylist = this.#hlsMediaPlaylistParser.hlsMediaPlaylist;

      if (hlsPlaylist !== undefined) {
        return {
          start: hlsPlaylist.start,
          end: Math.max(hlsPlaylist.start, hlsPlaylist.end - 3 * hlsPlaylist.targetDuration),
          isMovingWindow: hlsPlaylist.start > 0,
          isLiveDone: hlsPlaylist.endList,
        };
      }

      return {
        start: 0,
        end: 0,
        isMovingWindow: false,
        isLiveDone: false,
      };
    }

    return this.#playerManager.getLiveSeekableRange();
  }

  getDurationSec(): number {
    return this.#playerManager.getDurationSec();
  }

  getAbsoluteTimeForMediaTime(mediaTime: number): number {
    return mediaTime + this.getStartAbsoluteTime();
  }

  getMediaTimeForAbsoluteTime(absoluteTime: number): number {
    return absoluteTime - this.getStartAbsoluteTime();
  }

  manifestHandler = (manifest: string): string => {
    if (!this.#parsingPaused) {
      let hlsPlaylist: HlsMediaPlaylist | undefined;

      try {
        hlsPlaylist = this.#hlsMediaPlaylistParser.parseManifest(manifest);
      } catch (e) {
        // Probably a DASH stream. Which is fine.
        logger.warn('Failed to parse HLS manifest:', e);

        logger.log('Pausing HLS manifest parsing.');
        this.#parsingPaused = true;

        logger.log('Setting overrideLiveSeekableRange to false.');
        this.#overrideLiveSeekableRange = false;

        return manifest;
      }

      if (hlsPlaylist !== undefined) {
        const media = this.#playerManager.getMediaInformation();
        media.startAbsoluteTime = this.getStartAbsoluteTime();

        if (this.#useShakaForHls) {
          // Only need the startAbsoluteTime for HLS with Shaka.
          logger.log('Pausing HLS manifest parsing because Shaka only needs help with startAbsoluteTime.');
          this.#parsingPaused = true;
        } else if (!hlsPlaylist.endList && !this.#overrideLiveSeekableRange) {
          // Override the live seekable range when playing a live HLS stream
          // using MPL (not Shaka).
          logger.log('Setting overrideLiveSeekableRange to true because HLS is played using MPL.');
          this.#overrideLiveSeekableRange = true;
        }
      }
    }
    return manifest;
  };
}
