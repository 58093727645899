import document from 'global/document';
import { bind as hyper, BoundTemplateFunction } from 'hyperhtml';
import merge from 'lodash-es/merge';

export interface ErrorOverlayProps {
  title: string | null;
  body: string | null;
  reference: string | null;
  user: string | null;
}

function getDefaultProps(): ErrorOverlayProps {
  return {
    title: null,
    body: null,
    reference: null,
    user: null,
  };
}

const ACTIVE_CLASSNAME = 'error-overlay--active';

export default class ErrorOverlay {
  private props: ErrorOverlayProps;
  private containerElem: HTMLElement;
  private render: BoundTemplateFunction<HTMLElement>;

  constructor() {
    const containerElem = document.querySelector<HTMLElement>('.error-overlay');
    if (containerElem === null) {
      throw new Error('.error-overlay element missing');
    }

    this.props = getDefaultProps();
    this.containerElem = containerElem;
    this.render = hyper(this.containerElem);
    this.containerElem.addEventListener('transitionend', this.handleTransitionEnd);
  }

  update(newProps?: ErrorOverlayProps) {
    this.props = merge(this.props, newProps);

    const visible = !!this.props.title;

    this.containerElem.classList.toggle(ACTIVE_CLASSNAME, visible);

    if (!visible) {
      return;
    }

    this.render`
    <img class="error-overlay__image" src="/images/logo-corner.png" />
    <div class="error-overlay__message">
      <h3 class="error-overlay__heading">${this.props.title}</h3>
      <p class="error-overlay__body">${this.props.body}</p>
    </div>
    <div class="error-overlay__details">
      <div class="error-overlay__user">${this.props.user ? `Innlogget: ${this.props.user}` : 'Ikke innlogget'}</div>
      <div class="error-overlay__code" style=${{ display: this.props.reference ? '' : 'none' }}>Feilmelding: ${
        this.props.reference
      }</div>
    </div>
    `;
  }

  reset() {
    this.update(getDefaultProps());
  }

  private handleTransitionEnd = () => {
    if (!this.containerElem.classList.contains(ACTIVE_CLASSNAME)) {
      this.render``;
    }
  };
}
