import { getLogger } from '../logging/logger';
import GoogleAnalyticsListener from './GoogleAnalyticsListener';

const logger = getLogger('debugging');
let listener: GoogleAnalyticsListener;

export const analytics = {
  start: () => {
    if (listener) {
      return;
    }
    logger.log('Start listening to GA events');
    listener = new GoogleAnalyticsListener();
    listener.on('ga', (...args) => {
      logger.log('[GA]', ...args);
    });
  },
};

export function start() {
  analytics.start();
}
