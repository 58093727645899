import { EpgProgramSet } from './EpgTrackerEvents';
import { LiveEpgProgram } from './LiveEpgProgram';

const ADDITIONAL_LEAD_TIME = 30000;

export default class ProgramList {
  private programs: LiveEpgProgram[] = [];

  set(programs: LiveEpgProgram[]) {
    this.programs = programs;
  }

  /** liveTime is in milliseconds. */
  findByTime(liveTime: number, withAdditionalLeadTime?: boolean): EpgProgramSet {
    let previous: LiveEpgProgram | undefined;
    let current: LiveEpgProgram | undefined;
    let next: LiveEpgProgram | undefined;

    for (let i = this.programs.length - 1; i >= 0; i--) {
      const program = this.programs[i];

      if (program.actualStart - (withAdditionalLeadTime ? ADDITIONAL_LEAD_TIME : 0) > liveTime) {
        next = program;
      } else if (program.actualEnd - (withAdditionalLeadTime ? ADDITIONAL_LEAD_TIME : 0) <= liveTime) {
        previous = program;

        // Stop looking for more previous programs. One is sufficient and the
        // first is the right one. We're done looping from the end now.
        break;
      } else {
        current = program;
      }
    }

    return {
      previous,
      current,
      next,
    };
  }

  /** liveTime is in milliseconds. */
  isOutdated(liveTime: number): boolean {
    const lastProgram = this.getLastProgram();
    if (!lastProgram) {
      return false;
    }
    return liveTime > lastProgram.actualEnd;
  }

  /** fromLiveTime is in milliseconds. */
  getRemainingBufferTime(fromLiveTime: number): number {
    const lastProgram = this.getLastProgram();
    if (!lastProgram) {
      return -1;
    }
    // If there's only a previous program or only a next program then we're
    // outside the EPG.
    const programs = this.findByTime(fromLiveTime);
    if (!programs.current && (!programs.previous || !programs.next)) {
      return -1;
    }
    return lastProgram.actualEnd - fromLiveTime;
  }

  /** fromLiveTime is in milliseconds. */
  getRemainingTime(fromLiveTime: number): number {
    const programs = this.findByTime(fromLiveTime);
    if (programs.current !== undefined) {
      return programs.current.actualEnd - fromLiveTime;
    }
    if (programs.next !== undefined) {
      return programs.next.actualStart - fromLiveTime;
    }
    return -1;
  }

  private getLastProgram(): LiveEpgProgram | undefined {
    return this.programs && this.programs[this.programs.length - 1];
  }
}
