import { config } from '../appConfig';
import { httpGetJson } from '../http/httpGetJson';
import { IManifestsItem, IPlaybackMetadata } from './contracts/IPlaybackMetadata';
import { IPlaybackManifest } from './contracts/IPlaybackManifest';
import { mapPlaybackToMediaItem } from './mapPlaybackToMediaItem';
import { AuthenticationHandler } from '../CafPlayer/AuthenticationHandler';
import { getLogger } from '../logging/logger';
import { getMaxVideoLevel } from '../getChromecastModel';
import { defaultProfileSettings } from '../defaults';
import { MediaURI } from '../nrk/media-uri';

const logger = getLogger('getMediaItem');

export async function getMediaItem(
  contentId: string,
  preferredManifestNames: Array<string | undefined>,
  enableLiveSubtitles: boolean | undefined,
  headers?: Record<string, string>,
  authenticationHandler?: AuthenticationHandler
) {
  const newHeaders = appendDeviceInformationToAcceptHeader(headers);

  const mediaURI = MediaURI.parse(contentId);
  if (mediaURI === undefined) {
    throw new Error(`Unknown content ID: ${contentId}`);
  }

  const metadata = await httpGetJson<IPlaybackMetadata>(
    getPlaybackUrl(`/playback/metadata${mediaURI.asPath()}`, authenticationHandler),
    newHeaders
  );

  if (metadata.nonPlayable) {
    logger.log(metadata.nonPlayable);
  }

  const manifests: IManifestsItem[] = metadata._links.manifests || [];
  const manifestSubtitles: IManifestsItem[] = metadata._links.manifestSubtitles || [];
  const manifestReference = findPriorizedManifest(
    manifests,
    manifestSubtitles,
    preferredManifestNames,
    enableLiveSubtitles
  );

  const isPlayable = metadata.playability === 'playable';
  let manifest: IPlaybackManifest | undefined;
  if (isPlayable && manifestReference && manifestReference.href) {
    manifest = await httpGetJson<IPlaybackManifest>(
      getPlaybackUrl(manifestReference.href, authenticationHandler),
      newHeaders
    );
  }
  return mapPlaybackToMediaItem(metadata, manifest, manifestReference, manifestSubtitles, contentId);
}

function appendDeviceInformationToAcceptHeader(headers: Record<string, string> | undefined): Headers {
  const newHeaders = new Headers(headers ? { ...headers } : {});
  newHeaders.set('Accept', `application/vnd.nrk.psapi+json;version=9;${getAcceptHeaderDevicePart()}`);
  return newHeaders;
}

function getAcceptHeaderDevicePart() {
  const parts: string[] = ['device=chromecast'];
  const maxVideoLevel = getMaxVideoLevel();
  if (maxVideoLevel === '5.1') {
    parts.push('level51');
  } else if (maxVideoLevel === '4.2') {
    parts.push('level42');
  }
  return parts.join('-');
}

function getPlaybackUrl(path: string, authenticationHandler?: AuthenticationHandler) {
  const profileDefaults = defaultProfileSettings;
  const uri = new URL(path, config.apiBase);
  if (config.eeaPortability) {
    uri.searchParams.append('eea-portability', 'true');
  }
  uri.searchParams.append('live2vod', 'true');
  if (authenticationHandler) {
    uri.searchParams.append('contentGroup', authenticationHandler.contentGroup ?? profileDefaults.contentGroup.value);
    uri.searchParams.append(
      'ageRestriction',
      authenticationHandler.ageRestriction ?? profileDefaults.ageRestriction.value
    );
  }
  return uri.href;
}

export function findPriorizedManifest(
  manifests: IManifestsItem[],
  subtitleManifests: IManifestsItem[],
  preferredManifestNames: Array<string | undefined>,
  enableLiveSubtitles = false
) {
  if (enableLiveSubtitles) {
    // live subtitles enabled, select the correct manifest url
    const liveSubtitlesManifest = subtitleManifests.find((m) => m.name === 'all-speech-subtitles');
    if (liveSubtitlesManifest !== undefined) {
      return liveSubtitlesManifest;
    }
  }

  const prioritizedManifests = [...preferredManifestNames, 'default'];
  return (
    prioritizedManifests.map((name) => manifests.find((m) => m.name === name)).find((manifest) => manifest) ||
    manifests[0]
  );
}

const contentToLoadMap = {
  synstolk: /SYNS$/,
  tegntolk: /TOLK$/,
};
type ManifestKeys = keyof typeof contentToLoadMap;

export function getPreferredManifestByContentId(contentId: string) {
  return Object.keys(contentToLoadMap)
    .map((key) => key as ManifestKeys)
    .find((key) => contentToLoadMap[key].test(contentId));
}

export function getAuthHeaders(authenticationHandler: AuthenticationHandler) {
  const accessToken = authenticationHandler.accessToken;
  if (!accessToken) {
    return;
  }
  return {
    Authorization: `Bearer ${accessToken}`,
  };
}
